import { combineReducers } from "redux";
import Reducer from "./Reducer";
import ReducerShow from "./ReducerShow";
import CallReducerShow from "./CallRecucers";
import CallMinMax from "./CallMinMax";
import LeftMenuPanel from "./leftMenuPanelReducer";
import providerStatus from "./providerStatusReducer";
import IncomingCallStatus from "./incomingCallStatus";
import chatReducer from "./chatReducer";
import econsultsReducer from "./econsultsReducer";
import hospitalReducer from "./hospitalReducer";
import providerDetailsReducer from "./ProviderDetailsReducer";
import patientDetailsReducer from "./addPatientReducer";
import progressENote from "./progressEnoteReducer";
import teamDetailsReducer from "./TeamDetailsReducer";
import providerProfilePicReducer from "./providerProfilePicReducer";
import teamMemberReducer from "./teamMemberReducer";
import permissionPopupReducer from "./permissionPopupReducer";
import userReducer from "./userReducer";
import patientCensusReducer from "./patientCensusReducer";
import unsupportedBrowserReducer from "./unsupportedbrowserreducer";
import archivePatientReducer from "./archivePatientReducer";
import VitalsReducer from "./vitalsReducer";
import ZoomReducer from "./ZoomReducer";
import MiscReducer from "./MiscDataReducer";
export default combineReducers({
  Reducer,
  ReducerShow,
  CallReducerShow,
  CallMinMax,
  LeftMenuPanel,
  providerStatus,
  IncomingCallStatus,
  chatReducer,
  econsultsReducer,
  hospitalReducer,
  providerDetailsReducer,
  patientDetailsReducer,
  progressENote,
  teamDetailsReducer,
  providerProfilePicReducer,
  teamMemberReducer,
  permissionPopupReducer,
  userReducer,
  patientCensusReducer,
  unsupportedBrowserReducer,
  archivePatientReducer,
  VitalsReducer,
  ZoomReducer,
  MiscReducer,
});

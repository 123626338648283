import axios from "axios";
import { toast } from "react-toastify";
import { LocalStorageGetValue, browserDetails } from ".";
const token = "jhguyg398798574bjhb8y7987y";

export const generateEmailOTP = async (email, self) => {
  try {
    const res = await axios.post(`/api/userEndpoints/v1/forgot/password`, {
      email,
      token,
    });
    if (res.data.status) {
      self.setState({ isOtp: true, loading: false });
    } else {
      toast.error(res.data.errorMessage);
      self.setState({ isOtp: false, loading: false });
    }
  } catch (error) {
    console.log(error);
  }
};

export const generatePhoneOTP = async (countryCode, phoneNumber, self) => {
  try {
    const res = await axios.post(`/api/userEndpoints/v1/forgot/password`, {
      countryCode,
      phoneNumber,
      token,
    });
    if (res.data.status) {
      self.setState({ isOtp: true, loading: false });
    } else {
      toast.error(res.data.errorMessage);
      self.setState({ isOtp: false, loading: false });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getCountryCode = async () => {
  try {
    const res = await axios.get(`/api/userEndpoints/v1/getCountryList`);
    return res;
  } catch (error) {
    return error;
  }
};
export const submitEmailOTP = async (email, otp, self) => {
  try {
    const res = await axios.post(
      `/api/userEndpoints/v1/forgot/password/otp/verify`,
      {
        otp,
        token,
        email,
      }
    );
    self.setState({ loading: false });
    if (res.data.status) {
      self.setLocalStorage("user", res.data.user);
      self.setLocalStorage("accountAddress", "email");
      self.props.history.push("/new-password");
      window.history.replaceState(null, "", "/");
    } else {
      toast.error(res.data.errorMessage);
    }
  } catch (error) {
    self.setState({ loading: false });
    console.log(error);
  }
};

export const submitPhoneOTP = async (countryCode, phoneNumber, otp, self) => {
  try {
    const res = await axios.post(
      `/api/userEndpoints/v1/forgot/password/otp/verify`,
      {
        countryCode,
        otp,
        token,
        phoneNumber,
      }
    );

    self.setState({ loading: false });
    if (res.data.status) {
      self.setLocalStorage("user", res.data.user);
      self.setLocalStorage("accountAddress", "phone");
      self.props.history.push("/new-password");
      window.history.replaceState(null, "", "/");
    } else {
      toast.error(res.data.errorMessage);
    }
  } catch (error) {
    self.setState({ loading: false });
    toast.error("InValid OTP");
  }
};

export const submitEmailPassword = async (email, passwordNew, self) => {
  let token = JSON.parse(LocalStorageGetValue("user")).token;
  let browserName = browserDetails();
  try {
    const res = await axios.post(`/api/userEndpoints/v1/set/new/password`, {
      email,
      token,
      passwordNew,
      browserName: browserName,
      browserVersion: navigator.appVersion,
      osType: "Website",
    });

    self.setState({ loading: false });
    if (res.data.status && res.data.errorId === 1) {
      return res.data;
    } else {
      toast.error(res.data.errorMessage);
    }
  } catch (error) {
    console.log(error);
  }
};

export const submitPhonePassword = async (
  countryCode,
  phoneNumber,
  passwordNew,
  self
) => {
  let browserName = browserDetails();
  try {
    let token = JSON.parse(LocalStorageGetValue("user")).token;

    const res = await axios.post(`/api/userEndpoints/v1/set/new/password`, {
      countryCode,
      phoneNumber,
      token,
      passwordNew,
      browserName: browserName,
      browserVersion: navigator.appVersion,
      osType: "Website",
    });
    if (res.data.status) {
      self.props.history.push("/success");
      window.history.replaceState(null, "", "/");
    } else {
      self.setState({ loading: false });
      toast.error(res.data.errorMessage);
    }
  } catch (error) {
    console.log(error);
  }
};

import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import MicOff from "../../Assets/images/Mic_Off.svg";
import CamOff from "../../Assets/images/Camera_Off.svg";
import { useSelector, useDispatch } from "react-redux";
import { LocalStorageGetValue } from "../../Services/Auth";
import { setPermissionPopup } from "../../Redux/Actions/permissionPopupStateAction";
import Firebase from "firebase";
import {
  showminimum,
  showMessage,
  showGroupCallminimum,
  showGroupCallmaximum,
  showGroupModel,
  showmodel,
} from "../../Redux/Actions/callActions";

const usePermissionDenyStyles = makeStyles({
  dialogTitleWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  dialogTitle: { fontFamily: "Poppins", fontWeight: 700, fontSize: "1.125rem" },
  dialogPaper: { width: 600, borderRadius: 10 },
  closeIcon: { padding: 12 },
  mainText: {
    fontSize: "1.125rem",
    lineHeight: 1.5,
    fontWeight: 600,
    textAlign: "center",
  },
  subText: { fontSize: "0.75rem", lineHeight: 1.5, textAlign: "center" },
  dialogContentWrapper: {
    height: 196,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    fontFamily: "Poppins",
    padding: "0 78px",
  },
  buttonStyles: {
    paddingLeft: 20,
    paddingRight: 20,
    border: "2px solid #9B51E0",
    borderRadius: 8,
    color: "#9B51E0",
    transform: "translateX(-15px)",
    fontWeight: 600,
    height: 40,
    "&:hover": {
      border: "2px solid #9B51E0",
    },
  },
});

const PermissionDenyModel = () => {
  const dispatch = useDispatch();

  const permissionDenyModel = useSelector(
    ({ permissionPopupReducer: { permissionDenyModel } }) => permissionDenyModel
  );

  const showCallerWindow = useSelector((state) => state);
  const {
    dialogTitle,
    dialogTitleWrapper,
    dialogPaper,
    dialogContentWrapper,
    closeIcon,
    mainText,
    subText,
    buttonStyles,
  } = usePermissionDenyStyles();
  const temp = LocalStorageGetValue("user");
  const tempObject = JSON.parse(temp);
  const token = LocalStorageGetValue("token");
  const closePermissionDenyModel = () => {
    dispatch(setPermissionPopup(false));
    let agoraCallsData = {
      appId: "",
      channelName: "",
      agoraToken: "",
      initiaterID: "",
      typeofCall: "",
      show: false,
      receiverName: "",
      collapse: false,
    };
    if (showCallerWindow.CallReducerShow.groupCallShow) {
      dispatch(showGroupModel(agoraCallsData));
    } else if (showCallerWindow.CallReducerShow.show) {
      dispatch(showmodel(agoraCallsData));
    }
  };

  useEffect(() => {
    const starCountRef = Firebase.database().ref(
      "providers/" + tempObject?.uid
    );
    const FirebaseEventListener = (snapshot) => {
      const data = snapshot.val();
      if (
        data?.notification?.messageType === "audio_call" ||
        data?.notification?.messageType === "video_call" ||
        data?.notification?.messageType === "SOS"
      ) {
        dispatch(setPermissionPopup(false));
      }
    };
    starCountRef.on("value", FirebaseEventListener);
    return () => {
      starCountRef.off("value", FirebaseEventListener);
    };
  }, []);

  return (
    <Dialog open={permissionDenyModel} PaperProps={{ className: dialogPaper }}>
      <div className={dialogTitleWrapper}>
        <DialogTitle
          id="customized-dialog-title"
          onClose={closePermissionDenyModel}
        >
          <div className={dialogTitle}>Call Settings</div>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closePermissionDenyModel}
          className={closeIcon}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent dividers style={{ borderBottom: "inherit" }}>
        <div className={dialogContentWrapper}>
          <div>
            <img src={CamOff} />
            <img src={MicOff} />
          </div>
          <div className={mainText}>
            You haven’t allowed Omnicure Now Access to your camera and
            microphone
          </div>
          <div className={subText}>
            Allow Omnicure Now to use your camera and microphones so others on
            the call can see and hear you. You can turn it off later.
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          className={buttonStyles}
          onClick={closePermissionDenyModel}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PermissionDenyModel;

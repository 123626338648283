import produce from "immer";

const initialState = {
  teamDetails: [],
};

export default produce(function (draft, action) {
  if (typeof draft === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case "SAVETEAMDETAILS": {
      draft.teamDetails = action.payload;
      return draft;
    }
    default:
      return draft;
  }
});

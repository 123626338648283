import React, { Component, Suspense } from "react";
import "./App.scss";
import { LayoutRoute, LaunchLayout, MainLayout } from "../src/Pages/layouts";
import { Redirect, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import { Typography, CircularProgress } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import loadable, { lazy } from "@loadable/component";
import pMinDelay from "p-min-delay";
import { refreshTokenUpdates } from "../src/Services/HealthMonitor";
import {
  AES256_GCM_ENCRYPT,
  AES256_GCM_decrypt,
} from "../src/Services/Auth/encyptionDecryption";
import {
  LocalStorageClear,
  LocalStorageSetValue,
  LocalStorageGetValue,
} from "./Services/Auth";

const loaderJSX = (
  <div
    style={{
      width: "100vw",
      height: "100vh",
      display: "grid",
      placeItems: "center",
      position: "absolute",
      zIndex: 10,
    }}
  >
    <div style={{ display: "flex" }}>
      <CircularProgress open={true} />
      <Typography className="header-h3">{"Loading..."}</Typography>
    </div>
  </div>
);
const Dashboard = loadable(
  () => pMinDelay(import("../src/Pages/Dashbboard/Dashboard"), 200),
  { fallback: loaderJSX }
);
const AddPatient = loadable(
  () => pMinDelay(import("./Pages/AddPatient/AddPatient"), 200),
  { fallback: loaderJSX }
);
const ResetPasswordScreen = loadable(
  () =>
    pMinDelay(import("../src/Pages/ForgotPassword/ResetPasswordScreen"), 200),
  { fallback: loaderJSX }
);
const ExpiredPasswordScreen = loadable(
  () =>
    pMinDelay(
      import("../src/Pages/ExpiredPassword/ExpiredPasswordScreen"),
      200
    ),
  { fallback: loaderJSX }
);
const NewPasswordScreen = loadable(
  () => pMinDelay(import("./Pages/ForgotPassword/NewPasswordScreen"), 200),
  { fallback: loaderJSX }
);
const SuccessScreen = loadable(
  () => pMinDelay(import("./Pages/ForgotPassword/SuccessScreen"), 200),
  { fallback: loaderJSX }
);
const SuccessRegisteration = loadable(
  () => pMinDelay(import("./Pages/SignUpPage/Forms/SuccessRegisteration"), 200),
  { fallback: loaderJSX }
);
const LaunchComponent = lazy(() =>
  pMinDelay(import("../src/Pages/LaunchComponent"), 200)
);
const ExpiredSuccessScreen = loadable(
  () =>
    pMinDelay(import("../src/Pages/ExpiredPassword/ExpiredSuccessScreen"), 200),
  { fallback: loaderJSX }
);
const SettingsScreen = loadable(
  () => pMinDelay(import("../src/Pages/Userprofile/Settings"), 200),
  { fallback: loaderJSX }
);
const Support = loadable(
  () => pMinDelay(import("../src/Pages/layouts/contactSupport"), 200),
  { fallback: loaderJSX }
);
const Feedback = loadable(
  () => pMinDelay(import("../src/Pages/layouts/feedback"), 200),
  { fallback: loaderJSX }
);
const VerifcationPending = loadable(
  () => pMinDelay(import("./Pages/Login/VerifcationPending"), 200),
  { fallback: loaderJSX }
);
const OTPpagesLogin = loadable(
  () => pMinDelay(import("./Pages/Login/OTPpagesLogin"), 200),
  { fallback: loaderJSX }
);
const ProviderDirectory = loadable(
  () => pMinDelay(import("./Pages/ProviderDirectory1"), 200),
  { fallback: loaderJSX }
);
const Econsult = loadable(
  () => pMinDelay(import("./Pages/Econsults/Econsult"), 200),
  { fallback: loaderJSX }
);
const PatientCensus = loadable(
  () => pMinDelay(import("./Pages/PatientCensus/PatientCensusSection"), 200),
  { fallback: loaderJSX }
);
const NotificationDetails = loadable(
  () => pMinDelay(import("../src/Pages/Notification/notificationDetails"), 200),
  { fallback: loaderJSX }
);
const DashboardEconsultWrapper = loadable(
  () => pMinDelay(import("../src/Pages/Econsults/DashboardWrapper"), 200),
  { fallback: loaderJSX }
);
const PatientCensusEconsultWrapper = loadable(
  () => pMinDelay(import("../src/Pages/Econsults/PatientCensusWrapper"), 200),
  { fallback: loaderJSX }
);
const ArchiveDetails = loadable(
  () => pMinDelay(import("./Pages/ArchivedPatients/archivedDetails"), 200),
  { fallback: loaderJSX }
);
const ArchiveHomepage = loadable(
  () => pMinDelay(import("./Pages/ArchivedPatients/archivedHomepage"), 200),
  { fallback: loaderJSX }
);
const ConferenceHomePage = loadable(
  () => pMinDelay(import("./Pages/Conference/ConferenceHistory"), 200),
  { fallback: loaderJSX }
);
const AddConferencePage = loadable(
  () => pMinDelay(import("./Pages/Conference/AddConference"), 200),
  { fallback: loaderJSX }
);
let currentPath = sessionStorage.getItem("currentPath");

axios.interceptors.request.use(
  function (config) {
    return new Promise((resolve, reject) => {
      axios.defaults.headers.common["Content-Type"] = "application/json";
      const { data, method } = config;
      if (!process.env.REACT_APP_PRODUCTION_BUILD) {
        console.log(config.url, "config", data);
      }
      if (method === "post" && data && !data.hasOwnProperty("encryptedValue")) {
        AES256_GCM_ENCRYPT(JSON.stringify(data))
          .then((encryptedValue) => {
            const newConfig = { ...config, data: { encryptedValue } };
            resolve(newConfig);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve(config);
      }
    });
  },
  function (error) {
    return Promise.reject(error);
  },
  {
    runWhen: ({ url }) =>
      url !== "/api/userEndpoints/v1/versioninforesponse/Website",
  }
);

axios.interceptors.response.use(undefined, async function (error) {
  if (
    error?.response?.status === 502 ||
    error?.response?.status === 703 ||
    error?.response?.status === 704
  ) {
    try {
      let sAvailRefresh = LocalStorageGetValue("rToken");
      let passingObject = {
        token: sAvailRefresh,
      };
      const response = await axios.post(
        `/api/userEndpoints/v1/renewIdToken`,
        passingObject
      );
      const firebaseTokenTemp = response.data.idToken;
      const rTokenTemp = response.data.refreshToken;
      const idTokenTemp = response.data.idToken;
      LocalStorageSetValue("firebaseToken", firebaseTokenTemp);
      LocalStorageSetValue("rToken", rTokenTemp);
      LocalStorageSetValue("idToken", idTokenTemp);
      axios.defaults.headers.common["Authorization"] = idTokenTemp;
      error.config.headers.Authorization = idTokenTemp;
      const uninterceptedAxios = axios.create();
      return uninterceptedAxios(error.config);
      //return Promise.reject(error)
    } catch (error) {
      LocalStorageClear();
      sessionStorage.removeItem("currentPath");
      this.props.history.push("/login");
      window.history.replaceState(null, "", "/");
    }
  }
  return Promise.reject(error);
});

axios.interceptors.response.use(
  function (response) {
    return new Promise((resolve, reject) => {
      if (response?.data?.encryptedValue) {
        AES256_GCM_decrypt({ data: response.data })
          .then((decryptedValue) => {
            if (!process.env.REACT_APP_PRODUCTION_BUILD) {
              console.log(
                response.config.url,
                JSON.parse(decryptedValue),
                "Response_Config"
              );
            }
            const newResponse = {
              ...response,
              data: JSON.parse(decryptedValue),
            };
            resolve(newResponse);
          })
          .catch((error) => {
            if ([703, 502, 404].includes(error.response.status)) {
              refreshTokenUpdates(null);
              toast.warning("Something Wrong! Please Try again");
              reject(error);
            }
          });
      } else {
        resolve(response);
      }
    });
  },
  function (error) {
    return Promise.reject(error);
  }
);

let token = LocalStorageGetValue("token");
class App extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    const authToken = LocalStorageGetValue("idToken");
    axios.defaults.headers.common["Authorization"] = authToken;
    if (currentPath === null || currentPath === undefined) {
      if (token) {
        currentPath = sessionStorage.setItem("currentPath", "/dashboard");
      } else {
        currentPath = sessionStorage.setItem("currentPath", "/login");
      }
    }
    if (token) {
      if (currentPath !== null && currentPath !== undefined) {
        this.props.history.push(currentPath);
      } else {
        if (window.location.pathname !== "/login") {
          this.props.history.push("/dashboard");
        } else {
          this.props.history.push("/login");
        }
      }
    } else {
      this.props.history.push("/login");
    }
    window.history.replaceState(null, "", "/");
  }
  render() {
    return (
      <Suspense fallback={null}>
        <ToastContainer />
        <Switch>
          <LayoutRoute
            exact
            path="/login"
            layout={LaunchLayout}
            component={LaunchComponent}
          />
          <LayoutRoute
            exact
            path="/expired-success"
            layout={LaunchLayout}
            component={ExpiredSuccessScreen}
          />
          <LayoutRoute
            exact
            path="/register-success"
            layout={LaunchLayout}
            component={SuccessRegisteration}
          />

          <LayoutRoute
            exact
            path="/pending-otp"
            layout={LaunchLayout}
            component={OTPpagesLogin}
          />

          <LayoutRoute
            exact
            path="/reset-password"
            layout={LaunchLayout}
            component={ResetPasswordScreen}
          />
          <LayoutRoute
            exact
            path="/expired-password"
            layout={LaunchLayout}
            component={ExpiredPasswordScreen}
          />
          <LayoutRoute
            exact
            path="/new-password"
            layout={LaunchLayout}
            component={NewPasswordScreen}
          />
          <LayoutRoute
            exact
            path="/success"
            layout={LaunchLayout}
            component={SuccessScreen}
          />
          <LayoutRoute
            exact
            path="/settings"
            layout={MainLayout}
            component={SettingsScreen}
          />
          <LayoutRoute
            exact
            path="/dashboard"
            layout={MainLayout}
            component={Dashboard}
          />
          <LayoutRoute
            exact
            path="/provider-directory"
            layout={MainLayout}
            component={ProviderDirectory}
          />
          <LayoutRoute
            exact
            path="/support"
            layout={MainLayout}
            component={Support}
          />
          <LayoutRoute
            exact
            path="/feedback"
            layout={MainLayout}
            component={Feedback}
          />
          <LayoutRoute
            exact
            path="/verify-pending"
            layout={LaunchLayout}
            component={VerifcationPending}
          />
          <LayoutRoute
            exact
            path="/econsults"
            layout={MainLayout}
            component={(props) => (
              <DashboardEconsultWrapper
                EconsultComponent={Econsult}
                {...props}
              ></DashboardEconsultWrapper>
            )}
          />
          <LayoutRoute
            exact
            path="/econsults_PatientCensus"
            layout={MainLayout}
            component={(props) => (
              <PatientCensusEconsultWrapper
                EconsultComponent={Econsult}
                {...props}
              ></PatientCensusEconsultWrapper>
            )}
          />
          <LayoutRoute
            exact
            path="/patientCensus"
            layout={MainLayout}
            component={PatientCensus}
          />
          <LayoutRoute
            exact
            path="/notificationDetails"
            layout={MainLayout}
            component={NotificationDetails}
          />
          <LayoutRoute
            exact
            path="/addpatient"
            layout={MainLayout}
            component={AddPatient}
          />
          <LayoutRoute
            exact
            path="/archiveDetails"
            layout={MainLayout}
            component={ArchiveDetails}
          />
          <LayoutRoute
            exact
            path="/archiveHomepage"
            layout={MainLayout}
            component={ArchiveHomepage}
          />
          <LayoutRoute
            exact
            path="/conference"
            layout={MainLayout}
            component={ConferenceHomePage}
          />
          <LayoutRoute
            exact
            path="/addConference"
            layout={MainLayout}
            component={AddConferencePage}
          />
          <Redirect
            to={
              currentPath === null || currentPath === undefined
                ? token !== null && token !== undefined
                  ? "/dashboard"
                  : "/login"
                : currentPath
            }
          />
        </Switch>
      </Suspense>
    );
  }
}

export default App;

import axios from "axios";
import { LocalStorageGetValue } from "../Auth";

export const saveProfilePhoto = async (profilePicUrl) => {
  const userDetails = LocalStorageGetValue("userDetails");
  const providerId = JSON.parse(userDetails)?.id;
  const providerToken = LocalStorageGetValue("token");

  const params = {
    id: providerId,
    profilePicUrl,
    token: providerToken,
  };
  try {
    const response = await axios.post(
      `api/providerEndpoints/v1/updateProvider`,
      params
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

import React, {
  Component,
  useEffect,
  createRef,
  useRef,
  PureComponent,
} from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import {
  mergeDeepRight,
  forEachObjIndexed,
  dissocPath,
  dissoc,
  without,
  pluck,
  uniq,
  mergeLeft,
  not,
} from "ramda";
import Firebase from "firebase";
import moment from "moment";
import { LocalStorageGetValue } from "../../Services/Auth";
import {
  showGroupModel,
  updateGroupProviderData,
} from "../../Redux/Actions/callActions";
import { connect } from "react-redux";
import { styled } from "@mui/system";
import "./AgoraGroupCall.scss";
import { Typography, Avatar } from "@material-ui/core";
import "../../Styles/Typography/CustomTypography.scss";
import Group1171276208 from "../../Assets/images/SVGS/Group 1171276208 (2).svg";
import Group1171276202 from "../../Assets/images/SVGS/Group 1171276202 (2).svg";
import VolumeDown from "../../Assets/images/SVGS/Volume Down.svg";
import classNames from "classnames";
import Call2 from "../../Assets/images/SVGS/Call 2.svg";
import Ringtone from "../../Assets/images/ringtone.mp3";
import Group11712762083 from "../../Assets/images/SVGS/Group 1171276208 (3).svg";
import Group1171276194 from "../../Assets/images/SVGS/Group 1171276194.svg";
import VolumeOff from "../../Assets/images/SVGS/Volume Off.svg";
import { red } from "@material-ui/core/colors";
import {
  showGroupCallminimum,
  showGroupCallmaximum,
  callFeedBackShow,
  groupCallTeamMembers,
} from "../../Redux/Actions/callActions";
import { HealthMonitorCheckOnCalls } from "../../Services/HealthMonitor";
import { getTeamDetails } from "../../Services/ConsultsService";
import microphone from "../../Assets/images/microphone.svg";
import { isEmpty } from "lodash";
import { callConnectsaveCallAudit } from "../../Services/GroupCall/GroupCall";
import { toast } from "react-toastify";
let milliSeconds = 2 * 60 * 1000;

class TimerComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      secondsValue: 0,
    };
  }
  componentDidMount() {
    this.timerId = setInterval(() => {
      this.setState({
        secondsValue: moment().diff(moment(this.props.timeValue), "seconds"),
      });
    }, 1000);
  }
  render() {
    const { secondsValue } = this.state;
    console.log(this.localUserDetaiLs, this.localUserDetails?.displayName);
    return (
      <>{`${moment
        .duration(secondsValue, "seconds")
        .minutes()
        .toString()
        .padStart(2, "0")}:${moment
        .duration(secondsValue, "seconds")
        .seconds()
        .toString()
        .padStart(2, "0")}`}</>
    );
  }
  componentWillUnmount() {
    clearInterval(this.timerId);
  }
}

const RemoteContainer = styled("div")({
  display: "grid",
});

const LocalContainer = styled("div")({
  position: "absolute",
  top: 8,
  right: 8,
  "& .video-wrapper>div": {
    borderRadius: 10,
  },
  "& video": {
    borderRadius: 10,
  },
  "@media (orientation:portrait)": {
    "&": {
      transform: "scale(0.75)",
      top: -6,
      right: -6,
    },
  },
});

const ControlsContainer = styled("div")({
  display: "none",
  position: "absolute",
  justifyContent: "center",
  columnGap: 10,
  width: "100%",
  bottom: 20,
  zIndex: 100,
  ".bodyContentBG:hover &": {
    display: "flex",
  },
  "@media (hover: none) and (pointer: coarse)": {
    "&": {
      display: "flex",
    },
  },
});

class PlayerComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.containerRef = React.createRef();
  }
  componentDidMount() {
    if (!this.props?.video?.isPlaying) {
      this.props?.video?.play?.(this.containerRef.current);
      if (this.containerRef.current?.querySelector("video")) {
        this.containerRef.current.querySelector(
          "video"
        ).disablePictureInPicture = true;
      }
    }
    !this.props.localPlayer &&
      !this.props?.audio?.isPlaying &&
      this.props?.audio?.play?.();
    //(this.props.providerId,this.props.video,"didmountVideo")
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.video !== nextProps.video) {
      if (!nextProps?.video?.isPlaying) {
        nextProps?.video?.play?.(this.containerRef.current);
        if (this.containerRef.current?.querySelector("video")) {
          this.containerRef.current.querySelector(
            "video"
          ).disablePictureInPicture = true;
        }
      }
    }
    if (this.props.audio !== nextProps.audio) {
      !this.props.localPlayer &&
        !nextProps?.audio?.isPlaying &&
        nextProps?.audio?.play?.(this.containerRef.current);
    }
  }
  // componentDidUpdate(prevProps,prevState){
  //   if (this.props.video !== prevProps.video) {
  //     !this.props?.video?.isPlaying && this.props?.video?.play?.(this.containerRef.current)
  //     console.log(this.props.providerId,this.props.video,prevProps.video,this.props,prevProps,"willreceiveprops")
  //   }
  //   if (this.props.audio !== prevProps.audio) {
  //     !this.props.localPlayer && !this.props?.audio?.isPlaying && this.props?.audio?.play?.(this.containerRef.current)
  //   }
  // }
  render() {
    return (
      <div
        ref={this.containerRef}
        className="video-wrapper"
        {...(this.props.localPlayer && { id: "localVideoWrapper" })}
      ></div>
    );
  }
}
function supportAreaCode(params) {
  let supportedAreasArray = [];
  for (let i = 0; i < params.length; i++) {
    if (params[i] === "IN") {
      supportedAreasArray.push("INDIA");
    } else if (params[i] === "NA") {
      supportedAreasArray.push("NORTH_AMERICA");
    } else if (params[i] === "AS") {
      supportedAreasArray.push("ASIA");
    } else if (params[i] === "EU") {
      supportedAreasArray.push("EUROPE");
    } else if (params[i] === "JP") {
      supportedAreasArray.push("JAPAN");
    } else if (params[i] === "CN") {
      supportedAreasArray.push("CHINA");
    } else if (params[i] === "GL") {
      supportedAreasArray.push("GLOBAL");
    }
  }
  return supportedAreasArray;
}
class GroupCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      remoteUsers: {},
      userIDList: [],
      replicateUserIdList: [],
      providerList: null,
      local: {},
      timerValue: 0,
      timerStart: false,
      secondsValue: 0,
      agoraReconnecting: false,
      activeProvidersDatabase: [],
      timerData: {},
      callAttended: false,
      streamflag: {},
      agorasupportcode: props.AGORASUPPORTCODE?.split(","),
    };
    this.AgoraClient = AgoraRTC.createClient({ codec: "h264", mode: "rtc" });
    this.localUserDetaiLs = JSON.parse(LocalStorageGetValue("userDetails"));
    AgoraRTC.setArea(supportAreaCode(this.state.agorasupportcode));
    this.audio = new Audio(Ringtone);
    this.audio.loop = true;
    console.log(this.localUserDetaiLs, "localUserDetails");
  }

  initJoin = async (appID, channelName, tokenId, UID) => {
    await this.AgoraClient.join(appID, channelName, tokenId, parseInt(UID));
    await this.removeNotificationOnLogin();
  };

  removeNotificationOnLogin = () => {
    const dbRef1 = Firebase.database().ref("providers/");
    const userDetails = JSON.parse(LocalStorageGetValue("user"));
    dbRef1.child(userDetails.uid).child("notification").set(null);
  };

  leaveCall = async () => {
    let agoraCallsData = {
      appId: "",
      channelName: "",
      agoraToken: "",
      initiaterID: "",
      typeofCall: "",
      show: false,
      receiverName: "",
      collapse: false,
    };
    if (this.state.local?.audio) {
      this.state.local.audio.stop();
      this.state.local.audio.close();
    }
    if (this.state.local?.video) {
      this.state.local.video.stop();
      this.state.local.video.close();
    }
    this.props.openGroupCallWindow(agoraCallsData);
    this.props.saveGroupTeamMembers([]);
    try {
      await this.AgoraClient.unpublish();
      await this.AgoraClient.leave();
      await this.AgoraClient.removeAllListeners();
      await Promise.all(
        this.state.userList.map((users) => {
          this.AgoraClient.unsubscribe(users).catch((error) => {});
        })
      ).catch((error) => {
        console.log(error);
      });
    } catch (err) {
      console.log(err);
    }

    /*if (this.state.local != null) {
      this.state.local[0] && this.state.local[0].close();
      this.state.local[1] && this.state.local[1].close();
    }
    this.setState({ enablePlayer: false, local: null });
    setTimeout(async () => {
      await this.AgoraClient.unpublish();
      await this.AgoraClient.leave();
      await this.AgoraClient.removeAllListeners();
    }, 3000);
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((mediaStream) => {
        const stream = mediaStream;
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        stream.getTracks().forEach((track) => {
          if (track.readyState === "live" && track.kind === "video") {
            track.stop();
          }
        });
      });
    this.state.userList.forEach(async (users) => {
      await this.AgoraClient.unsubscribe(users);
    });
    let testToken = LocalStorageGetValue("token");
    let ids = JSON.parse(LocalStorageGetValue("id"));
    const url = `api/providerEndpoints/v1/sendMessage`;
    let params;
    if (this.state.remote.length === 0) {
      params = {
        providerId: ids,
        token: testToken.replace(/"/g, ""),
        receiverId: this.state.reciverId,
        message: "callDisconnected",
        type: "callDisconnected",
      };
    } else if (rejectMessage !== null && rejectMessage === undefined) {
      params = {
        providerId: ids,
        token: testToken.replace(/"/g, ""),
        receiverId: this.state.reciverId,
        message: rejectMessage,
        type: "callEnd",
      };
    }
    checkingProviderStatus(url, params).then(() => {
      this.setState({ openCallModal: false });
    });
    this.props.enableCallIcons?.();*/
  };
  videoHandler = async () => {
    if (this.state?.local?.video) {
      this.state.local.video
        .setEnabled(!this.state?.local?.video?._enabled)
        .then(() => {
          this.forceUpdate();
        });
    } else {
      const localVideoTrack = await AgoraRTC.createCameraVideoTrack().catch(
        (error) => {
          console.log(
            error,
            "----------------------------------->localVideoTrackError<------------------------------------"
          );
        }
      );
      console.log(
        localVideoTrack,
        "----------------------------------->localVideoTrack<------------------------------------"
      );
      if (localVideoTrack) {
        await this.AgoraClient.publish(localVideoTrack);
      }
      this.setState((state) => {
        state.local = mergeDeepRight(state.local, { video: localVideoTrack });
        return { ...state };
      });
    }
  };

  audioHandler = async () => {
    if (this.state?.local?.audio) {
      this.state.local.audio
        .setEnabled(!this.state?.local?.audio?._enabled)
        .then(() => {
          this.forceUpdate();
        });
    } else {
      const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack().catch(
        (error) => {
          console.log(
            error,
            "----------------------------------->localAudioTrackError<------------------------------------"
          );
        }
      );
      console.log(
        localAudioTrack,
        "----------------------------------->localAudioTrack<------------------------------------"
      );
      if (localAudioTrack) {
        await this.AgoraClient.publish(localAudioTrack);
      }
      this.setState((state) => {
        state.local = mergeDeepRight(state.local, { audio: localAudioTrack });
        return { ...state };
      });
    }
  };
  publishLocalStream = async (typeofCall) => {
    if (typeofCall === "audio_call") {
      await AgoraRTC.createMicrophoneAudioTrack()
        .then(async (localAudioTrack) => {
          console.log(
            "------------------------------------------------------->AudioCall<----------------------------------------",
            localAudioTrack
          );
          this.setState({ local: { audio: localAudioTrack } });
          this.AgoraClient.publish([localAudioTrack]);
        })
        .catch((error) => {
          console.log(
            "------------------------------------------------------->MicrophoneError<---------------------------",
            error
          );
          //   this.setState({ local: [], muteAudio: true });
        });
    } else if (typeofCall === "video_call") {
      await AgoraRTC.createMicrophoneAndCameraTracks()
        .then(async (track) => {
          let [localAudioTrack, localVideoTrack] = track;
          console.log(
            "------------------------------------------------------->AudioCall<----------------------------",
            localAudioTrack
          );
          this.setState(
            { local: { audio: localAudioTrack, video: localVideoTrack } },
            () => {}
          );
          this.AgoraClient.publish([localAudioTrack, localVideoTrack]);
        })
        .catch((error) => {
          console.log(
            "------------------------------------------------------->CameraError<--------------------------------------",
            error
          );
          //this.setState({ local: [] }, () => { });
        });
    }
  };

  collapseHandler = () => {
    //document.getElementsByClassName('GroupCallStyleDOM')[0].style.transform='translate(0px,0px)'
    if (this.props.minimizeView) {
      this.props.maximizeWindow();
    } else if (this.props.maximizeView) {
      this.props.minimizeWindow();
    }
  };

  componentWillUnmount = async () => {
    //clearInterval(this.timerId)
    if (this.noUser) {
      clearTimeout(this.noUser);
    }
    clearInterval(this.interval);
    this.audio.pause();
    this.state.local?.audio && this.state.local?.audio?.stop();
    this.state.local?.video && this.state.local?.video?.stop();
    this.state.local?.audio && this.state.local?.audio?.close();
    this.state.local?.video && this.state.local?.video?.close();
    try {
      await this.AgoraClient.unpublish();
      await this.AgoraClient.leave();
      await this.AgoraClient.off();
      await this.AgoraClient.removeAllListeners();
      await this.starCountRef.off();
      if (this.state.callAttended) {
        await this.props.callFeedBackShow();
      }
    } catch (err) {}
    // await Promise.all(this.state.userList.map(async (users) => {
    //   await this.AgoraClient.unsubscribe(users);
    // }))
  };

  MonitorSession = async () => {
    let token = LocalStorageGetValue("token");
    let id = LocalStorageGetValue("id");
    if (token && id) {
      await HealthMonitorCheckOnCalls();
    }
  };

  InitiateCall = async () => {
    this.AgoraClient.on("user-published", async (user, mediaType) => {
      this.setState((state) => {
        const { userList } = state;
        if (userList.findIndex((list) => list.uid === user.uid) === -1) {
          return { ...state, userList: [...userList, user] };
        }
        return { ...state };
      });
      // Initiate the subscription
      await this.AgoraClient.subscribe(user, mediaType);
      if (mediaType === "video") {
        const remoteVideoTrack = user.videoTrack;
        this.setState((state, props) => {
          const { remoteUsers } = state;
          const updatedRemoteUsers = mergeDeepRight(
            { ...remoteUsers },
            { [user.uid]: { video: remoteVideoTrack } }
          );
          return {
            ...state,
            remoteUsers: updatedRemoteUsers,
          };
        });
      }
      if (mediaType === "audio") {
        const remoteAudioTrack = user.audioTrack;
        this.setState((state, props) => {
          const { remoteUsers } = state;
          const updatedRemoteUsers = mergeDeepRight(
            { ...remoteUsers },
            { [user.uid]: { audio: remoteAudioTrack } }
          );
          return {
            ...state,
            remoteUsers: updatedRemoteUsers,
          };
        });
      }
    });
    this.AgoraClient.on(
      "connection-state-change",
      async (curState, revState, reason) => {
        if (curState === "RECONNECTING") {
          this.setState({ agoraReconnecting: true });
          this.reconnectTimer = setTimeout(() => {
            this.leaveCall();
            this.setState({ agoraReconnecting: false });
          }, 30000);
        } else if (curState === "CONNECTED" && revState === "RECONNECTING") {
          clearTimeout(this.reconnectTimer);
          this.setState({ agoraReconnecting: false });
        }

        // this.reconnectingTimer = setTimeout(()=>{
        //   this.leaveCall()
        // },3000)
        /* if (curState === "RECONNECTING") {
           reconnectingStatus = setTimeout(() => {
             if (curState === "RECONNECTING") {
               this.leaveCall(LAST_CONNECTION);
               this.setState({ agoraReconnecting: false });
               clearInterval(reconnectingStatus);
             }
           }, 30000);
           this.setState({ agoraReconnecting: true });
         } else if (curState === "CONNECTED") {
           clearInterval(reconnectingStatus);
           this.setState({ agoraReconnecting: false });
           const { appId, channelName, agoraToken, initiaterID, typeofCall } =
             this.props.popUpWindow.CallReducerShow.agoraCallsData;
           await this.initJoin(appId, channelName, agoraToken, initiaterID);
           await this.publishLocalStream(typeofCall);
         } else if (curState === "ABORTED") {
           this.leaveCall(ABORTED);
         } else if (curState === "DISCONNECTED") {
           navigator.mediaDevices
             .getUserMedia({ video: false, audio: true })
             .then((mediaStream) => {
               const stream = mediaStream;
               stream.getTracks().forEach((track) => {
                 track.stop();
               });
               stream.getTracks().forEach((track) => {
                 if (track.readyState === "live" && track.kind === "video") {
                   track.stop();
                 }
               });
             });
         }*/
      }
    );
    this.AgoraClient.on("user-left", async ({ uid }, reason) => {
      /*let agoraCallsData = {
        appId: "",
        channelName: "",
        agoraToken: "",
        initiaterID: "",
        typeofCall: "",
        show: false,
        receiverName: "",
        collapse: false,
      };
      this.props.showCallMessage("reset", false, "");
      setTimeout(() => {
        this.props.openCallWindow(agoraCallsData);
      }, 0);*/
      this.setState((state, props) => {
        const { userIDList, remoteUsers, timerData, streamflag } = state;
        // let {remoteUsers} = state;
        // remoteUsers=dissoc(uid,remoteUsers)
        const itemIndex = userIDList.indexOf(uid);
        if (itemIndex !== -1) {
          const newOldList = [...userIDList];
          newOldList.splice(itemIndex, 1);
          //delete remoteUsers[uid]
          return {
            ...state,
            userIDList: newOldList,
            remoteUsers: dissoc(uid, remoteUsers),
            timerData: dissoc(uid, timerData),
            streamflag: dissoc(uid, streamflag),
          };
        }
        return state;
      });
    });
    this.AgoraClient.on("user-joined", async ({ uid }) => {
      /*clearInterval(this.state.startAudio);
      this.state.audio.pause();*/
      //this.audio.pause();
      clearTimeout(this.ringerId);
      clearTimeout(this.openAgoraWindow);
      this.props.saveGroupTeamMembers(uniq([...this.state.userIDList, uid]));
      this.setState({ callAttended: true });
      this.setState((state, props) => {
        const { userIDList, replicateUserIdList, timerData, remoteUsers } =
          state;
        const updatedRemoteUsers = mergeLeft(remoteUsers, { [uid]: {} });
        // let {remoteUsers} = state;
        // remoteUsers = {...remoteUsers,[uid]:{}}
        if (!userIDList.includes(uid)) {
          return {
            ...state,
            userIDList: [...userIDList, uid],
            timerData: { ...timerData, [uid]: new Date().getTime() },
            replicateUserIdList: [...replicateUserIdList, uid],
            enableTimer: true,
            remoteUsers: updatedRemoteUsers,
          };
        }
        return state;
      });
      const teamProvidersIdList = pluck(
        "id",
        this.props.groupCallProviderData || []
      );
      if (!teamProvidersIdList.includes(uid)) {
        const {
          teamDetails: { members },
        } = await getTeamDetails(
          this.props.agoraCallDetails.patientId,
          this.props.agoraCallDetails.teamName
        );
        const newAddedProvider = members.find(
          ({ providerId }) => uid === providerId
        );
        const {
          providerId: id,
          providerName: name,
          rpType: remoteProviderType,
        } = newAddedProvider;
        this.props.updateGroupCallProviderList({
          id,
          name,
          remoteProviderType,
        });
      }
      // const {userIDList} = this.state;
      // if(!userIDList.includes(uid)){
      //   this.props.saveGroupTeamMembers([...userIDList,uid])
      // }
      // window.setTimeout(()=>{
      //   this.setState({streamflag:{...this.state.streamflag,[uid]:true}})
      // },750)
    });
    this.AgoraClient.on("user-unpublished", async (user, mediaType) => {
      this.setState((state, props) => {
        let { remoteUsers } = state;
        remoteUsers = dissocPath([user.uid, mediaType], remoteUsers);
        return {
          ...state,
          remoteUsers,
        };
      });
    });
    const { appId, channelName, agoraToken, initiaterID, typeofCall } =
      this.props.agoraCallDetails;
    await this.initJoin(appId, channelName, agoraToken, initiaterID);
    await this.publishLocalStream(typeofCall);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.userIDList !== this.state.userIDList) {
      if (prevState.userIDList.length === 0) {
        if (this.noUser) {
          clearTimeout(this.noUser);
        }
        // this.timerId=setInterval(()=>{
        //         this.setState({secondsValue:this.state.secondsValue+1})
        //       },1000)
      }
      if (isEmpty(this.state.userIDList) && !isEmpty(prevState.userIDList)) {
        this.noUser = setTimeout(() => {
          this.leaveCall();
          this.props.disconnectCallHandler();
        }, 30000);
      }
    }
    if (prevState.replicateUserIdList !== this.state.replicateUserIdList) {
      if (
        prevState.replicateUserIdList.length >
        this.state.replicateUserIdList.length
      ) {
        if (!this.state.replicateUserIdList.length) {
          Promise.resolve()
            .then(this.leaveCall)
            .then(this.props.disconnectCallHandler);
        }
      }
    }
    if (
      prevState.activeProvidersDatabase !== this.state.activeProvidersDatabase
    ) {
      if (
        prevState.activeProvidersDatabase.length >
        this.state.activeProvidersDatabase.length
      ) {
        if (
          !this.state.activeProvidersDatabase.length &&
          isEmpty(this.state.userIDList)
        ) {
          Promise.resolve()
            .then(this.leaveCall)
            .then(this.props.disconnectCallHandler);
        }
      }
    }
  }

  componentDidMount() {
    if (this.props.callInitiated) {
      this.audio.play();
    }
    const temp = LocalStorageGetValue("user");
    const tempObject = JSON.parse(temp);
    const { id: selfProviderId } = JSON.parse(
      LocalStorageGetValue("userDetails") || {}
    );
    this.setState({
      activeProvidersDatabase: without(
        [selfProviderId],
        pluck("id", this.props.groupCallProviderData)
      ),
    });
    this.interval = setInterval(this.MonitorSession, milliSeconds);
    this.starCountRef = Firebase.database().ref(
      "providers/" + tempObject?.uid + "/notification"
    );
    this.starCountRef.on("value", (snapshot) => {
      const data = snapshot.val();
      if (
        data?.messageType === "audio_call" ||
        data?.messageType === "video_call"
      ) {
        if (this.props.agoraCallDetails?.channelName === data?.message) {
          const { name } = JSON.parse(data?.Provider);
          toast.warn(`${name} joined`);
        }
      }
      if (
        data?.messageType === "CallerReject" ||
        data?.messageType === "callDisconnected"
      ) {
        const { id: leftProviderId } = JSON.parse(data?.Provider || {});
        const { replicateUserIdList, activeProvidersDatabase } = this.state;
        this.setState({
          replicateUserIdList: without([leftProviderId], replicateUserIdList),
          activeProvidersDatabase: without(
            [leftProviderId],
            activeProvidersDatabase
          ),
        });
        // this.setState(state=>{
        //   const {replicateUserIdList} = state;
        //   const leftProviderIdIndex = replicateUserIdList.indexOf(leftProviderId)
        //   replicateUserIdList.splice(leftProviderIdIndex,1)
        //   return {...state,replicateUserIdList:[...replicateUserIdList]}
        // })
      }
    });
    // this.setState((state,props)=>{
    //   const selfUserId = LocalStorageGetValue('id')
    //   for (const {id} of props.agoraCallDetails.groupCallProviderData){
    //     if(!state.remoteUsers.hasOwnProperty(id) && id!=selfUserId){
    //       state.remoteUsers[id]={}
    //     }
    //   }
    //   return {...state}
    // })
    this.InitiateCall();
    this.openAgoraWindow = setTimeout(() => {
      this.leaveCall();
    }, 30000);
  }

  render() {
    return (
      <>
        <div className="root-Parent">
          {!this.state.userIDList.length && (
            <div className="connecting_status_group">
              <p>Connecting</p>
              <div class="lds-ellipsis">
                <div className="firstDot"></div>
                <div className="firstDot"></div>
                <div className="firstDot"></div>
                <div className="firstDot"></div>
              </div>
            </div>
          )}
          {this.state.agoraReconnecting && (
            <div className="connecting_status_group">
              <p>Reconnecting</p>
              <div class="lds-ellipsis">
                <div className="firstDot"></div>
                <div className="firstDot"></div>
                <div className="firstDot"></div>
                <div className="firstDot"></div>
              </div>
            </div>
          )}
          {!this.state.agoraReconnecting && (
            <RemoteContainer
              className={classNames({
                [`agoraGroupcallgrid--${this.state.userIDList.length}`]:
                  this.props.maximizeView,
                [`agoraGroupcallgridmin--${this.state.userIDList.length}`]:
                  this.props.minimizeView,
              })}
            >
              {Object.entries(this.state.remoteUsers).map(
                ([providerId, { video, audio }], index) => {
                  return (
                    <div
                      className={`root-user-div-label-${index + 1}`}
                      key={providerId}
                      style={{ position: "relative" }}
                    >
                      {video
                        ? Boolean(
                            this.props.agoraCallDetails.groupCallProviderData.find(
                              (item) => item.id == providerId
                            )
                          ) && (
                            <div
                              className="video-user-left-label"
                              style={
                                this.props.minimizeView
                                  ? {
                                      boxSizing: "border-box",
                                      minHeight: "initial",
                                      width: 70,
                                      padding: 10,
                                    }
                                  : { boxSizing: "content-box" }
                              }
                              key={providerId + "video"}
                            >
                              {this.props.maximizeView ? (
                                <>
                                  <Typography
                                    className="content-emphasized-text-button"
                                    style={{
                                      textAlign: "start",
                                      width: "100%",
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <span
                                      style={{
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        wordBreak: "break-all",
                                        maxWidth: "95%",
                                      }}
                                    >
                                      {
                                        this.props.agoraCallDetails.groupCallProviderData.find(
                                          (item) => item.id == providerId
                                        )?.name
                                      }
                                    </span>
                                    <span>{`${
                                      this.props.agoraCallDetails.groupCallProviderData
                                        .find((item) => item.id == providerId)
                                        ?.remoteProviderType.includes(
                                          "Bedside Clinician"
                                        ) === false
                                        ? ","
                                        : ""
                                    }`}</span>{" "}
                                    <span>{`${
                                      (() => {
                                        const providerDetails =
                                          this.props.agoraCallDetails.groupCallProviderData.find(
                                            (item) =>
                                              item.id == providerId &&
                                              !item.remoteProviderType.includes(
                                                "Bedside Clinician"
                                              )
                                          );
                                        return (
                                          providerDetails?.displayName ||
                                          providerDetails?.remoteProviderType
                                        );
                                      })() || ""
                                    }`}</span>
                                  </Typography>
                                  <Typography
                                    className="captions-sub-default"
                                    style={{
                                      color: "white",
                                      textAlign: "start",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    {(() => {
                                      const providerDetails =
                                        this.props.agoraCallDetails.groupCallProviderData.find(
                                          (item) => item.id == providerId
                                        );
                                      return providerDetails?.remoteProviderType.includes(
                                        "Bedside Clinician"
                                      )
                                        ? providerDetails?.displayName ||
                                            "Bedside Clinician"
                                        : "Remote Clinician";
                                    })()}
                                  </Typography>
                                  <Typography
                                    className="captions-sub-default"
                                    style={{
                                      color: "white",
                                      paddingTop: "3px",
                                    }}
                                  >
                                    <TimerComponent
                                      timeValue={
                                        this.state.timerData[providerId]
                                      }
                                    />
                                    {!audio && (
                                      <img
                                        height="15px"
                                        width="15px"
                                        alt="Name"
                                        src={microphone}
                                        style={{ verticalAlign: "sub" }}
                                      />
                                    )}
                                  </Typography>
                                </>
                              ) : this.props.minimizeView ? (
                                <>
                                  <Typography
                                    style={{
                                      textAlign: "start",
                                      width: "100%",
                                      fontSize: 11,
                                    }}
                                  >
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        wordBreak: "break-all",
                                        color: "#FFF",
                                      }}
                                    >
                                      {this.props.agoraCallDetails.groupCallProviderData
                                        .find((item) => item.id == providerId)
                                        ?.fname.replace(/[\s'.-]/g, "")
                                        .charAt(0) +
                                        this.props.agoraCallDetails.groupCallProviderData
                                          .find((item) => item.id == providerId)
                                          ?.lname.replace(/[\s'.-]/g, "")
                                          .charAt(0)}
                                      {/* {this.props.agoraCallDetails.groupCallProviderData
                                        .find((item) => item.id == providerId)
                                        ?.name.replace(/[\s'.-]/g, "")
                                        .charAt(0) +
                                        this.props.agoraCallDetails.groupCallProviderData
                                          .find((item) => item.id == providerId)
                                          ?.name.split(" ")[1]
                                          .replace(/[\s'.-]/g, "")
                                          .charAt(0)} */}
                                    </div>
                                    <div style={{ color: "#FFF" }}>
                                      {this.props.agoraCallDetails.groupCallProviderData
                                        .find((item) => item.id == providerId)
                                        ?.remoteProviderType.includes(
                                          "Bedside Clinician"
                                        )
                                        ? "BP"
                                        : this.props.agoraCallDetails.groupCallProviderData.find(
                                            (item) => item.id == providerId
                                          )?.remoteProviderType}
                                    </div>
                                  </Typography>
                                  <Typography
                                    className="captions-sub-default"
                                    style={{
                                      color: "white",
                                      paddingTop: "3px",
                                      width: "max-content",
                                    }}
                                  >
                                    <TimerComponent
                                      timeValue={
                                        this.state.timerData[providerId]
                                      }
                                    />
                                    {!audio && (
                                      <img
                                        height="15px"
                                        width="15px"
                                        alt="Name"
                                        src={microphone}
                                        style={{ verticalAlign: "sub" }}
                                      />
                                    )}
                                  </Typography>
                                </>
                              ) : null}
                            </div>
                          )
                        : Boolean(
                            this.props.agoraCallDetails.groupCallProviderData.find(
                              (item) => item.id == providerId
                            )
                          ) && (
                            <div
                              className="align-center"
                              key={providerId + "audio"}
                            >
                              {this.props.maximizeView ? (
                                <>
                                  <Typography
                                    className="captions-sub-default"
                                    style={{ color: "white", marginBottom: 10 }}
                                  >
                                    <TimerComponent
                                      timeValue={
                                        this.state.timerData[providerId]
                                      }
                                    />
                                  </Typography>
                                  <Avatar
                                    src={
                                      this.props.profilePicsCollection.find(
                                        ({ id }) => id == providerId
                                      )?.profilePicUrl
                                    }
                                    style={{
                                      ...{
                                        background: "white",
                                        height: "80px",
                                        width: "80px",
                                        display: "flex",
                                      },
                                      ...(this.props.minimizeView && {
                                        width: 52,
                                        height: 52,
                                      }),
                                    }}
                                  >
                                    <Typography className="content-emphasized-label">
                                      {this.props.agoraCallDetails.groupCallProviderData
                                        .find((item) => item.id == providerId)
                                        ?.fname.replace(/[\s'.-]/g, "")
                                        .charAt(0) +
                                        this.props.agoraCallDetails.groupCallProviderData
                                          .find((item) => item.id == providerId)
                                          ?.lname.replace(/[\s'.-]/g, "")
                                          .charAt(0)}
                                    </Typography>
                                  </Avatar>
                                  <div className="user-detail-label">
                                    <Typography
                                      className="content-emphasized-text-button"
                                      style={{
                                        textAlign: "start",
                                        width: "100%",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "center",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      <span
                                        style={{
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          wordBreak: "break-all",
                                          maxWidth: "95%",
                                        }}
                                      >
                                        {
                                          this.props.agoraCallDetails.groupCallProviderData.find(
                                            (item) => item.id == providerId
                                          )?.name
                                        }
                                      </span>
                                      <span>{`${
                                        this.props.agoraCallDetails.groupCallProviderData
                                          .find((item) => item.id == providerId)
                                          ?.remoteProviderType.includes(
                                            "Bedside Clinician"
                                          ) === false
                                          ? ","
                                          : ""
                                      }`}</span>
                                      <span>{`${(() => {
                                        const providerDetails =
                                          this.props.agoraCallDetails.groupCallProviderData.find(
                                            (item) =>
                                              item.id == providerId &&
                                              !item.remoteProviderType.includes(
                                                "Bedside Clinician"
                                              )
                                          );
                                        return (
                                          providerDetails?.displayName ||
                                          providerDetails?.remoteProviderType ||
                                          ""
                                        );
                                      })()}`}</span>
                                    </Typography>
                                    <Typography
                                      className="captions-sub-default"
                                      style={{
                                        color: "white",
                                        overflowWrap: "break-word",
                                        textAlign: "center",
                                      }}
                                    >
                                      {(() => {
                                        const providerDetails =
                                          this.props.agoraCallDetails.groupCallProviderData.find(
                                            (item) => item.id == providerId
                                          );
                                        return providerDetails?.remoteProviderType.includes(
                                          "Bedside Clinician"
                                        )
                                          ? providerDetails?.displayName ||
                                              "Bedside Clinician"
                                          : "Remote Clinician";
                                      })()}
                                      {!audio && (
                                        <img
                                          height="15px"
                                          width="15px"
                                          alt="Name"
                                          src={microphone}
                                          style={{ verticalAlign: "sub" }}
                                        />
                                      )}
                                    </Typography>
                                  </div>
                                </>
                              ) : this.props.minimizeView ? (
                                <>
                                  <Typography
                                    className="captions-sub-default"
                                    style={{ color: "white", marginBottom: 10 }}
                                  >
                                    <TimerComponent
                                      timeValue={
                                        this.state.timerData[providerId]
                                      }
                                    />
                                    {!audio && (
                                      <img
                                        height="15px"
                                        width="15px"
                                        alt="Name"
                                        src={microphone}
                                        style={{ verticalAlign: "sub" }}
                                      />
                                    )}
                                  </Typography>
                                  <Avatar
                                    src={
                                      this.props.profilePicsCollection.find(
                                        ({ id }) => id == providerId
                                      )?.profilePicUrl
                                    }
                                    style={{
                                      ...{
                                        background: "white",
                                        height: "80px",
                                        width: "80px",
                                        display: "flex",
                                      },
                                      ...(this.props.minimizeView && {
                                        width: 52,
                                        height: 52,
                                      }),
                                    }}
                                  >
                                    <Typography className="content-emphasized-label">
                                      {this.props.agoraCallDetails.groupCallProviderData
                                        .find((item) => item.id == providerId)
                                        ?.fname.replace(/[\s'.-]/g, "")
                                        .charAt(0) +
                                        this.props.agoraCallDetails.groupCallProviderData
                                          .find((item) => item.id == providerId)
                                          ?.lname.replace(/[\s'.-]/g, "")
                                          .charAt(0)}
                                      {/* {this.props.agoraCallDetails.groupCallProviderData
                                        .find((item) => item.id == providerId)
                                        ?.name.replace(/[\s'.-]/g, "")
                                        .charAt(0) +
                                        this.props.agoraCallDetails.groupCallProviderData
                                          .find((item) => item.id == providerId)
                                          ?.name.split(" ")[1]
                                          .replace(/[\s'.-]/g, "")
                                          .charAt(0)} */}
                                    </Typography>
                                  </Avatar>
                                  <Typography
                                    className="captions-sub-default"
                                    style={{ color: "#FFF", paddingTop: 10 }}
                                  >
                                    {this.props.agoraCallDetails.groupCallProviderData
                                      .find((item) => item.id == providerId)
                                      ?.remoteProviderType.includes(
                                        "Bedside Clinician"
                                      )
                                      ? "BP"
                                      : this.props.agoraCallDetails.groupCallProviderData.find(
                                          (item) => item.id == providerId
                                        )?.remoteProviderType}
                                  </Typography>
                                </>
                              ) : null}
                            </div>
                          )}
                      <PlayerComponent
                        video={video}
                        audio={audio}
                        providerId={providerId}
                        key={providerId + "playerComponent"}
                      />
                    </div>
                  );
                }
              )}
            </RemoteContainer>
          )}
          <ControlsContainer>
            <div
              className="controls_groupCall"
              style={{
                ...(this.props.minimizeView && {
                  height: "56px",
                  cursor: "pointer",
                }),
                ...((Object.entries(this.state.remoteUsers).some(
                  ([, { video }]) => video
                ) ||
                  this.state?.local?.video?._enabled) && {
                  backgroundColor: "#172B4D",
                }),
              }}
              onTouchStart={this.videoHandler}
              onClick={this.videoHandler}
            >
              <img
                alt="video"
                src={
                  this.state?.local?.video?._enabled
                    ? Group1171276208
                    : Group11712762083
                }
              />
              <span
                style={{ ...(this.props.minimizeView && { display: "none" }) }}
              >
                {this.state?.local?.video?._enabled ? "Video Off" : "Video On"}
              </span>
            </div>
            <div
              className="controls_groupCall"
              style={{
                ...(this.props.minimizeView && {
                  height: "56px",
                  cursor: "pointer",
                }),
                ...((Object.entries(this.state.remoteUsers).some(
                  ([, { video }]) => video
                ) ||
                  this.state?.local?.video?._enabled) && {
                  backgroundColor: "#172B4D",
                }),
              }}
              onTouchStart={this.audioHandler}
              onClick={this.audioHandler}
            >
              <img
                alt="video"
                src={
                  this.state?.local?.audio?._enabled ? VolumeDown : VolumeOff
                }
              />
              <span
                style={{ ...(this.props.minimizeView && { display: "none" }) }}
              >
                {this.state?.local?.audio?._enabled ? "Mute" : "Unmute"}
              </span>
            </div>
            <div
              className="controls_groupCall"
              style={{
                ...(this.props.minimizeView && {
                  height: "56px",
                  cursor: "pointer",
                }),
                ...((Object.entries(this.state.remoteUsers).some(
                  ([, { video }]) => video
                ) ||
                  this.state?.local?.video?._enabled) && {
                  backgroundColor: "#172B4D",
                }),
              }}
              onTouchStart={this.collapseHandler}
              onClick={this.collapseHandler}
            >
              <img
                alt="video"
                src={
                  this.props.maximizeView ? Group1171276202 : Group1171276194
                }
              />
              <span
                style={{ ...(this.props.minimizeView && { display: "none" }) }}
              >
                {"Minimize"}
              </span>
            </div>
            <div
              className="controls_groupCall"
              style={{
                cursor: "pointer",
                backgroundColor: "#E52727",
                ...(this.props.minimizeView && { height: "56px" }),
              }}
              onTouchStart={() => {
                this.leaveCall();
                this.props.disconnectCallHandler();
              }}
              onClick={() => {
                this.leaveCall();
                this.props.disconnectCallHandler();
              }}
            >
              <img alt="Call2" src={Call2} />
              <span
                style={{ ...(this.props.minimizeView && { display: "none" }) }}
              >
                {"End Call"}
              </span>
            </div>
          </ControlsContainer>
          {
            <LocalContainer
              style={{
                ...((this.props.minimizeView ||
                  not(
                    !this.state.agoraReconnecting &&
                      ((Object.keys(this.state.remoteUsers).length &&
                        Object.entries(this.state.remoteUsers).some(
                          ([, { video }]) => video
                        )) ||
                        this.state?.local?.video?._enabled)
                  )) && { visibility: "hidden" }),
              }}
            >
              <div
                className="root-user-div-label"
                style={
                  this.state.local.video?._enabled
                    ? {
                        height: 139,
                        width: 139,
                        borderRadius: 10,
                      }
                    : {
                        maxWidth: 139,
                        minHeight: 139,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        background: "#172B4D",
                        borderRadius: 10,
                        width: 139,
                        border: "1px solid #FFF",
                      }
                }
              >
                {/* <div className="video-user-left-label" key={index}>
                  <Typography className="content-emphasized-text-button" style={{ textAlign: "start" }}>
                    {"Maria Paul, MD"}
                  </Typography>
                  <Typography className="captions-sub-default" style={{ color: "white" }}>
                    {"Bedside Provider"}
                  </Typography>
                  <Typography className="captions-sub-default" style={{ color: "white", paddingTop: "3px" }}>
                    {"00:03"}
                  </Typography>
                </div> */}

                {!this.state.local.video?._enabled && (
                  <div className="align-center">
                    <Avatar
                      style={{
                        background: "#FFF",
                        height: "52px",
                        width: "52px",
                        display: "flex",
                      }}
                      src={this.localUserDetaiLs?.profilePicUrl}
                    >
                      <Typography className="content-emphasized-label">
                        {this.localUserDetaiLs.fname
                          .replace(/[\s'.-]/g, "")
                          .charAt(0) +
                          this.localUserDetaiLs.lname
                            .replace(/[\s'.-]/g, "")
                            .charAt(0)}
                      </Typography>
                    </Avatar>
                    <div
                      className="user-detail-label-local"
                      style={{ width: "100%" }}
                    >
                      <Typography
                        className="content-emphasized-text-button"
                        style={{
                          textAlign: "center",
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            wordBreak: "break-all",
                            maxWidth: "95%",
                          }}
                        >
                          {this.localUserDetaiLs?.name}
                        </span>
                        <span>{`${
                          Boolean(this.localUserDetaiLs?.remoteProviderType)
                            ? ","
                            : ""
                        }`}</span>{" "}
                        <span>{`${
                          this.localUserDetaiLs.remoteProviderType
                            ? this.localUserDetaiLs?.displayName ||
                              this.localUserDetaiLs.remoteProviderType
                            : ""
                        }`}</span>
                      </Typography>
                      <Typography
                        className="captions-sub-default"
                        style={{ color: "#FFF", textAlign: "center" }}
                      >
                        {this.localUserDetaiLs?.role === "BD"
                          ? this.localUserDetaiLs?.displayName ||
                            "Bedside Clinician"
                          : this.localUserDetaiLs?.role === "RD"
                          ? "Remote Clinician"
                          : ""}
                      </Typography>
                    </div>
                  </div>
                )}
                <PlayerComponent
                  video={this.state.local.video}
                  audio={this.state.local.audio}
                  localPlayer
                />
              </div>
            </LocalContainer>
          }
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openGroupCallWindow(data) {
      dispatch(showGroupModel(data));
    },
    updateGroupCallProviderList(data) {
      dispatch(updateGroupProviderData(data));
    },
    minimizeWindow() {
      dispatch(showGroupCallminimum());
    },
    maximizeWindow() {
      dispatch(showGroupCallmaximum());
    },
    callFeedBackShow() {
      dispatch(callFeedBackShow());
    },
    saveGroupTeamMembers(data) {
      dispatch(groupCallTeamMembers(data));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    agoraCallDetails: state.CallReducerShow.agoraCallsData,
    groupCallProviderData:
      state.CallReducerShow.agoraCallsData?.groupCallProviderData,
    providerList: state.providerProfilePicReducer.providerProfilePics,
    maximizeView: state.CallMinMax.showGroupCallMaxi,
    minimizeView: state.CallMinMax.showGroupCallMini,
    profilePicsCollection: state.providerProfilePicReducer.providerProfilePics,
    AGORASUPPORTCODE: state.userReducer.AGORASUPPORTCODE,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupCall);

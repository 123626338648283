import produce from "immer";

const initialState = {
  specialityTypes: [],
};

export default produce((draft, action) => {
  if (typeof draft === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case "SET_SPECIALITYDATA":
      draft.specialityTypes = action.payload;
      break;
    default:
      break;
  }
});

import React, { Component } from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import classNames from "classnames";
import PageVisibility from "react-page-visibility";
import { connect } from "react-redux";
import {
  ListItemText,
  Modal,
  Menu,
  MenuItem,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  IconButton,
  ListItem,
  Typography,
  withStyles,
  fade,
  ListItemIcon,
  Link,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";
import KeyboardArrowLeftOutlinedIcon from "@material-ui/icons/KeyboardArrowLeftOutlined";
import logoIcon from "../../Assets/images/SVGS/Group 845.svg";
import NavArrow from "../../Assets/images/navArrow.svg";
import { withTranslation } from "react-i18next";
import ModelsControl from "./callerWindow";

import SnackbarComponent from "../AgoraCalls/SnackbarComponent";
import Sidebar from "./Sidebar";
import "./MainLayout.scss";
import Firebase from "firebase";
import {
  leftPanelCollapsed,
  leftPanelExpanded,
} from "../../Redux/Actions/leftMenuPanelAction";
import { incomingCallAction } from "../../Redux/Actions/incomingCallAction";
import { resetCall } from "../../Redux/Actions/callActions";
import { HealthMonitorCheck } from "../../Services/HealthMonitor";
import { detect } from "detect-browser";
import { cond, always, T } from "ramda";
import {
  continueAuthenticates,
  removeClickedSessionTO,
  removeClickedSessionTo,
} from "../../Services/CommonService";
import { checkingProviderStatus } from "../../Services/AudVidCalls/AudVidCalls";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import { firebaseConfig } from "../../firebase-config";
import {
  LocalStorageSetValue,
  LocalStorageGetValue,
} from "../../Services/Auth";
import Header from "./header.js";
import {
  backgroundcolor,
  secondarycolor24,
  primarycolor1,
  secondarycolor6,
} from "../../Styles/Colors/colorCodes.js";
import LogoutAlert from "./LogoutAlert";
import LoaderDialog from "../Dashbboard/LoaderDialog";
import CallFeedBack from "./CallFeedBack";
import { isEmpty, pluck, without, not } from "ramda";
import PermissionDenyModel from "./permissionDenyModel";
import { AES256_GCM_ENCRYPT } from "../../Services/Auth/encyptionDecryption";
import { eConsultPage } from "../../Redux/Actions/econsultsAction";
import { patientCensusPage } from "../../Redux/Actions/patientCensusAction";
import { toggleUnsupportedBrowser } from "../../Redux/Actions/unsupportedbrowseraction";
import { specialityData } from "../../Redux/Actions/providerDetailsAction";
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}
// eslint-disable-next-line
import backArrow from "../../Assets/images/SVGS/Caret Left.svg";
const drawerWidth = 243;
let milliSeconds = 1 * 60 * 1000;
const styles = (theme) => ({
  backDrop: {
    backdropFilter: "blur(13px)",
  },
  avatar: {
    margin: 10,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - 80px)`,
    },
    [theme.breakpoints.down("md")]: {
      width: `calc(100% - 80px)`,
    },
    borderBottomColor: "darkgray",
    borderBottomWidth: "thin",
    borderBottomStyle: "solid",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: backgroundcolor,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    borderBottomColor: "darkgray",
    borderBottomWidth: "thin",
    borderBottomStyle: "solid",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  hide: {
    display: "block",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    background: primarycolor1,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "80px",
    [theme.breakpoints.up("sm")]: {
      width: "80px",
    },

    background: primarycolor1,
  },
  drawerOpenRight: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginTop: 100,
    overflowY: "inherit",
    height: 245,

    background: "linear-gradient(180deg,#4e73df 10%,#224abe 100%)",
    borderRadius: "0 0 0 10px",
  },
  drawerCloseRight: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowY: "inherit",
    width: 0,
    marginTop: 100,
    height: 245,
    background: "linear-gradient(180deg,#4e73df 10%,#224abe 100%)",
    borderRadius: "0 0 0 10px",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "left",
    marginRight: "13px",

    justifyContent: "flex-end",
  },
  drawermainHeader: {
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    background: secondarycolor24,
    flexGrow: 1,
    overflowY: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    paddingLeft: "5px",
  },
  inputRoot: {
    "&::placeholder": {
      color: "black",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
    background: "#FFFFF",
    borderWidth: 4,
    borderColor: secondarycolor6,
    border: "solid",
    borderRadius: "8px",
    "&::placeholder": {
      color: "black",
    },
  },
  appBarDialog: {
    position: "relative",
  },
  flex: {
    flex: 1,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  margin: {
    margin: theme.spacing.unit,
  },
  button: {
    margin: 10,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: `calc(100% - ${theme.spacing.unit * 2}px)`,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    color: "black",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  arrowIconButton: {
    boxShadow: "0 0px 12px !important",
    position: "fixed !important",
    background: "white !important",
    width: "33px",
    height: "33px",
  },
  arrowIcon: { color: "black" },
  divIconButton: {
    boxShadow: "0 0px 5px",
    borderRadius: "50%",
    position: "fixed",
    background: "white",
    marginTop: "40vh",
  },
});

function UnsupportedBrowserBanner() {
  return (
    <div
      style={{
        backgroundColor: "#5E6C84",
        width: "100%",
        position: "fixed",
        color: "#B3BAC5",
        fontSize: 14,
        lineHeight: "24px",
        fontFamily: "PoppinsRegular",
        textAlign: "center",
      }}
    >
      For the best experience please use a supported browser{" "}
      <Link
        style={{ color: "#FFF" }}
        target="_blank"
        href="https://omnicurenow.com/browsers"
      >
        See All
      </Link>
    </div>
  );
}

const browserCond = cond([
  [(name) => name.match(/chrome|chromium|crios/i), always(98)],
  [(name) => name.match(/firefox|fxios/i), always(98)],
  [(name) => name.match(/edg/i), always(100)],
  [(name) => name.match(/safari/i), always(14)],
  [T, always("unsupported browser")],
]);

const { name: browserName, os, type, version } = detect();

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModel: false,
      open: false,
      isTabActive: true,
      currentAPIRunning: false,
      showPassword: false,
      authenticateModel: false,
      lockedUserModel: false,
      sessionTimeOutModel: false,
      providerDenied: false,
      deniedusertitle: "",
      deniedusermessage: "",
      invalidateTitle: "",
      invalidateMessage: "",
      activeOnAnotherDevice: false,
      sessionTimeOutMessage: "",
      authenticatePassword: null,
      anchorEl: null,
      isDialog: false,
      searchString: "",
      right: false,
      groupCallNotify: false,
      mobileMoreAnchorEl: null,
      userInfo: null,
      popupState: null,
      notification: null,
      openSnackbar: false,
      new_messageMessageType: null,
      CallerRejectMessageType: null,
      audio_callMessageType: null,
      video_callmessageType: null,
      sos_callMessageType: null,
      sos_dismiss: null,
      noAnwsering: null,
      callDisconnected: null,
      callProgress: false,
      logoutAlertFlag: false,
      logoutLoader: false,
      groupCallDetails: {},
      portraitMode: false,
      sendHealthMonitorDisable: false,
    };

    this.focusOnApplication = React.createRef();
    const sTemp = LocalStorageGetValue("user");
    const sAvail = LocalStorageGetValue("rToken");
    const refreshtokens = JSON.parse(sTemp);
    this.callMonitorApiIn();
    let tempRefreshs = refreshtokens?.stsTokenManager?.refreshToken;
    const refreshTokens =
      tempRefreshs !== undefined || tempRefreshs !== null ? tempRefreshs : null;
    if (sAvail === undefined || sAvail === null || sAvail === "") {
      if (refreshTokens) {
        AES256_GCM_ENCRYPT(refreshTokens).then((encryptedRefreshTokens) => {
          this.setLocalStorage("rToken", encryptedRefreshTokens);
        });
      }
    }
  }
  setLocalStorage = (storageVariable, storageData) => {
    LocalStorageSetValue(
      storageVariable,
      typeof storageData === "string"
        ? storageData
        : JSON.stringify(storageData)
    );
  };
  onBackButtonEvent = () => {
    this.setState({ isDialog: false, searchString: "" });
  };
  removeNotificationOnRefresh = () => {
    const dbRef1 = Firebase.database().ref("providers/");
    const userDetails = JSON.parse(LocalStorageGetValue("user"));
    dbRef1.child(userDetails.uid).child("notification").set(null);
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  callMonitorApiIn = async () => {
    let token = LocalStorageGetValue("token");
    let id = LocalStorageGetValue("id");
    if (token && id && !this.state.sendHealthMonitorDisable) {
      HealthMonitorCheck(this);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.authenticateModel === false &&
      this.state.authenticateModel === true
    ) {
      this.setState({
        authenticateModel: true,
        disabled: true,
        logoutAlertFlag: false,
      });
    }
  }
  changeStatevalue = () => {
    this.setState({ authenticatePassword: this.state.authenticatePassword });
  };

  handleGroupCallFlag = () => {
    this.setState({
      openSnackbar: false,
      message: "",
      notification: null,
      new_messageMessageType: null,
      CallerRejectMessageType: null,
      callDisconnected: null,
      messageType: null,
      video_callmessageType: null,
      sos_callMessageType: "",
      sos_dismiss: null,
      audio_callMessageType: null,
      noAnwsering: null,
      groupCallDetails: {},
    });
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  listentoWindow = (isVisible) => {
    this.setState({
      isTabActive: isVisible,
    });
  };
  componentDidMount = () => {
    this.props.getspecialityData();
    const browserVersion = browserCond(browserName);
    const currentBrowserVersion = Number(version.split(".")[0]);
    if (os.match(/Windows/)) {
      if (
        browserVersion === "unsupported browser" ||
        browserVersion > currentBrowserVersion
      ) {
        this.props.toggleUnsupportedBrowser();
        render(
          <UnsupportedBrowserBanner />,
          document.getElementById("unsupportedbrowser")
        );
      }
    } else if (os.match(/Mac/)) {
      if (navigator.maxTouchPoints > 1) {
        this.props.toggleUnsupportedBrowser();
        render(
          <UnsupportedBrowserBanner />,
          document.getElementById("unsupportedbrowser")
        );
      } else if (
        browserVersion === "unsupported browser" ||
        browserVersion > currentBrowserVersion
      ) {
        this.props.toggleUnsupportedBrowser();
        render(
          <UnsupportedBrowserBanner />,
          document.getElementById("unsupportedbrowser")
        );
      }
    } else if (os.match(/iOS|Android/)) {
      this.props.toggleUnsupportedBrowser();
      render(
        <UnsupportedBrowserBanner />,
        document.getElementById("unsupportedbrowser")
      );
    }
    const portraitMode = window.matchMedia("(orientation:portrait)");
    portraitMode.onchange = (e) => {
      this.setState({ portraitMode: e.matches });
    };
    this.setState({ portraitMode: portraitMode.matches });
    let token = LocalStorageGetValue("token");
    if (!token) {
      this.props.history.push("/login");
      window.history.replaceState(null, "", "/");
    }

    window.onpopstate = this.onBackButtonEvent;
    let userDetails = LocalStorageGetValue("userDetails");
    this.setState({ userInfo: JSON.parse(userDetails) });
    window.addEventListener("focus", this.onFocus);
    const temp = LocalStorageGetValue("user");
    const tempObject = JSON.parse(temp);
    ReactDOM.findDOMNode(this.refs.divFocus).focus();
    if (window.performance) {
      if (performance.navigation.type === 1) {
        this.callMonitorApiIn();
      }
    }
    if (window.performance) {
      if (performance.navigation.type === 1) {
        const dbRef1 = Firebase.database().ref("providers/");
        const userDetails1 = JSON.parse(LocalStorageGetValue("user"));
        dbRef1.child(userDetails1.uid).child("notification").set(null);
        if (this.props.popUpWindow.CallReducerShow.show) {
          const testToken = LocalStorageGetValue("token");
          const ids = JSON.parse(LocalStorageGetValue("id"));
          let patient;
          if (this.props.notification?.Patient) {
            patient = JSON.parse(this.props.notification?.Patient);
          }
          const url = `api/providerEndpoints/v1/sendMessage`;
          const params = {
            providerId: ids,
            patientId: patient ? patient?.patientId : "",
            token: testToken.replace(/"/g, ""),
            receiverId: this.state.reciverId,
            message: "callDisconnected",
            type: "callDisconnected",
          };
          checkingProviderStatus(url, params).then(() => {
            this.setState({ openCallModal: false });
          });
        }
      }
    }
    if (!this.state.isTabActive) {
    } else {
      this.interval = setInterval(this.MonitorSession, milliSeconds);
    }

    const starCountRef = Firebase.database().ref(
      "providers/" + tempObject?.uid
    );
    starCountRef.on("value", (snapshot) => {
      const data = snapshot.val();
      if (
        this.state.groupCallDetails.hasOwnProperty(
          Number(data?.notification?.message1)
        )
      ) {
        if (
          data?.notification?.messageType === "callDisconnected" ||
          data?.notification?.messageType === "CallerReject" ||
          data?.notification?.messageType === "CallerNotAnswer"
        ) {
          const usersArray =
            this.state.groupCallDetails[data?.notification?.message1];
          const leftOutUser = JSON.parse(
            data?.notification?.Provider || {}
          )?.id;
          const newUsers = without([leftOutUser], usersArray);
          if (newUsers.length) {
            this.setState((state) => {
              const groupCallDetails = {
                [data?.notification?.message1]: newUsers,
              };
              return { ...state, groupCallDetails };
            });
            return;
          } else {
            this.setState({
              openSnackbar: false,
              message: "",
              notification: null,
              new_messageMessageType: null,
              CallerRejectMessageType: null,
              callDisconnected: null,
              messageType: null,
              video_callmessageType: null,
              sos_callMessageType: "",
              sos_dismiss: null,
              audio_callMessageType: null,
              noAnwsering: null,
              groupCallDetails: {},
            });
            return;
          }
        }
      } else {
        if (
          data?.notification?.message1 &&
          !data?.notification?.message1?.includes("-") &&
          !isNaN(Number(data?.notification?.message1)) &&
          (data?.notification?.messageType === "callDisconnected" ||
            data?.notification?.messageType === "CallerReject" ||
            data?.notification?.messageType === "CallerNotAnswer")
        ) {
          if (this.props.agoraCallsData.channelName) {
            if (
              not(
                this.props?.agoraCallsData?.channelName ===
                  data?.notification?.message1
              )
            ) {
              return;
            }
          } else {
            return;
          }
        }
      }
      if (isEmpty(this.state.groupCallDetails)) {
        this.setState({
          openSnackbar: false,
          message: "",
          notification: null,
          new_messageMessageType: null,
          CallerRejectMessageType: null,
          callDisconnected: null,
          messageType: null,
          video_callmessageType: null,
          sos_callMessageType: "",
          sos_dismiss: null,
          audio_callMessageType: null,
          noAnwsering: null,
        });
      }
      if (
        !data?.notification?.message?.includes("-") &&
        (data?.notification?.messageType === "audio_call" ||
          data?.notification?.messageType === "video_call")
      ) {
        if (isEmpty(this.state.groupCallDetails)) {
          const temp = LocalStorageGetValue("userDetails");
          const { id: selfUserId } = JSON.parse(temp);
          this.setState({
            groupCallDetails: {
              [data?.notification?.message]: without(
                [selfUserId],
                pluck("id", JSON.parse(data?.notification?.ProviderList || {}))
              ),
            },
          });
        } else {
          return;
        }
      }
      if (
        data !== null &&
        data.notification !== null &&
        data.notification !== undefined &&
        data.notification !== "undefined"
      ) {
        if (data.notification.messageType === "new_message") {
          this.setState({
            openSnackbar: true,
            notification: data.notification,
            new_messageMessageType: data.notification.messageType,
          });
        } else if (
          data.notification.messageType === "Logout" &&
          data.notification.title === "Session Invalidated" &&
          JSON.parse(LocalStorageGetValue("userDetails")) &&
          data.notification.token ===
            JSON.parse(LocalStorageGetValue("userDetails")).token
        ) {
          this.props.resetCall();
          if (
            data.notification.message ===
            "You have been logged out by the system administrator for performing system maintenance. Please log back in after some time."
          ) {
            this.setState({
              invalidateTitle: data.notification.title,
              invalidateMessage: data.notification.message,
              activeOnAnotherDevice: false,
              kickout: true,
              sendHealthMonitorDisable: true,
            });
          } else {
            this.setState({
              invalidateTitle: data.notification.title,
              invalidateMessage: data.notification.message,
              activeOnAnotherDevice: true,
              kickout: false,
              sendHealthMonitorDisable: true,
            });
          }
        } else if (data.notification.messageType === "CallerReject") {
          const { id: ProviderId } = JSON.parse(data.notification.Provider);
          if (
            data?.notification?.message1 &&
            !data?.notification?.message1.includes("-")
          ) {
            if (
              !this.props.agoraCallsData.channelName ||
              this.props.teamgroupMembers.includes(ProviderId)
            ) {
              return;
            }
          }
          this.setState({
            openSnackbar: true,
            notification: data.notification,
            CallerRejectMessageType: data.notification.messageType,
          });
        } else if (data.notification.messageType === "audio_call") {
          if (
            this.props.agoraCallsData.channelName === data.notification.message
          ) {
            return;
          }
          const { patientId, teamName } =
            Object.values(data.active || {}).find(
              ({ patientId }) => patientId == data.notification.message
            ) || {};
          let callStatus = {
            incomingCallStatus: true,
          };
          this.props.isIncoming(callStatus);
          this.setState({
            openSnackbar: true,
            notification: {
              ...data.notification,
              ...(patientId && { patientId }),
              ...(teamName && { teamName }),
            },
            audio_callMessageType: data.notification.messageType,
          });
        } else if (data.notification.messageType === "video_call") {
          if (
            this.props.agoraCallsData.channelName === data.notification.message
          ) {
            return;
          }
          const { patientId, teamName } =
            Object.values(data.active || {}).find(
              ({ patientId }) => patientId == data.notification.message
            ) || {};
          let callStatus = {
            incomingCallStatus: true,
          };
          this.props.isIncoming(callStatus);
          this.setState({
            openSnackbar: true,
            notification: {
              ...data.notification,
              ...(patientId && { patientId }),
              ...(teamName && { teamName }),
            },
            video_callmessageType: data.notification.messageType,
          });
        } else if (data.notification.messageType === "SOS") {
          let callStatus = {
            incomingCallStatus: true,
          };
          this.props.isIncoming(callStatus);
          this.setState({
            openSnackbar: true,
            notification: data.notification,
            sos_callMessageType: data.notification.messageType,
          });
        } else if (data.notification.messageType === "sos_dismiss") {
          this.setState({
            openSnackbar: true,
            notification: data.notification,
            sos_dismiss: data.notification.messageType,
          });
        } else if (data.notification.messageType === "callDisconnected") {
          this.setState({
            openSnackbar: true,
            notification: data.notification,
            callDisconnected: data.notification.messageType,
          });
        } else if (
          data.notification.messageType === "No Answering" ||
          data.notification.messageType === "CallerNotAnswer"
        ) {
          const { id: ProviderId } = JSON.parse(data.notification.Provider);
          if (
            data?.notification?.message1 &&
            !data?.notification?.message1.includes("-")
          ) {
            if (
              !this.props.agoraCallsData.channelName ||
              this.props.teamgroupMembers.includes(ProviderId)
            ) {
              return;
            }
          }
          this.setState({
            openSnackbar: true,
            notification: data.notification,
            noAnwsering: data.notification.messageType,
          });
        }
      } else if (
        data?.notification?.messageType === "Denied User" &&
        data?.notification?.title === "Denied User" &&
        JSON.parse(LocalStorageGetValue("userDetails")) &&
        data?.notification?.token ===
          JSON.parse(LocalStorageGetValue("userDetails")).token
      ) {
        this.setState({
          providerDenied: true,
          deniedusermessage: data.notification.messagedata.notification.message,
          deniedusertitle: data.notification.title,
          sendHealthMonitorDisable: true,
        });
      } else {
        if (isEmpty(this.state.groupCallDetails)) {
          this.setState({
            openSnackbar: false,
            message: "",
            notification: null,
            messageType: null,
          });
        }
      }
    });
  };
  handleDrawerOpen = () => {
    let { open } = this.state;
    this.setState({ open: !open }, () => {
      if (this.state.open) {
        this.props.leftExpand();
      }
      if (!this.state.open) {
        this.props.leftCollapse();
      }
      if (this.props.popUpWindow.CallMinMax.showMaxi && this.state.open) {
        if (document.getElementsByClassName("CallStyleDOM").length > 0) {
          document.getElementsByClassName("CallStyleDOM")[0].style.right =
            "70%";
        }
      }
      if (this.props.popUpWindow.CallMinMax.showMaxi && !this.state.open) {
        if (document.getElementsByClassName("CallStyleDOM").length > 0) {
          document.getElementsByClassName("CallStyleDOM")[0].style.right =
            "19%";
        }
      }
      // eslint-disable-next-line
      if (
        (!this.props.popUpWindow.CallMinMax.showMaxi && this.state.open) ||
        !this.state.open
      ) {
        if (document.getElementsByClassName("CallStyleDOM").length > 0) {
          document.getElementsByClassName("CallStyleDOM")[0].style.right = "3%";
        }
      }
    });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleDialogOpen = () => {
    this.setState({ isDialog: true });
  };

  handleDialogClose = () => {
    this.setState({ isDialog: false, searchString: "" });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleClose = () => {
    this.setState({});
  };

  handleTextFieldChange(event) {
    event.preventDefault();
    this.setState({
      authenticatePassword: event.target.value,
    });
    if (this.state.authenticatePassword !== null) {
      if (this.state.authenticatePassword.length > 0) {
        this.setState({ disabled: false });
      }
    }
  }
  checkOnfocusblur = async () => {
    this.setState({ isTabActive: false });
  };
  checkOnfocusfocus = async () => {
    if (true || !this.props.zoomWindowOpen) {
      this.setState({ isTabActive: true });
      await this.callMonitorApiOnfocus();
      this.setState({ isTabActive: true });
    }
  };
  callMonitorApiOnfocus = async () => {
    let token = LocalStorageGetValue("token");
    let id = LocalStorageGetValue("id");
    if (token && id && !this.state.sendHealthMonitorDisable) {
      HealthMonitorCheck(this);
    }
  };

  MonitorSession = async () => {
    let token = LocalStorageGetValue("token");
    let id = LocalStorageGetValue("id");
    if (token && id) {
      if (
        this.state.isTabActive &&
        !this.state.sendHealthMonitorDisable /*&& !this.props.zoomWindowOpen*/
      ) {
        HealthMonitorCheck(this);
      }
    }
  };

  handleKeyPress = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  logout = () => {
    this.setState({ logoutAlertFlag: !this.state.logoutAlertFlag });
  };

  continueAuthenticate = async () => {
    if (
      this.state.authenticatePassword !== null &&
      this.state.authenticatePassword.length > 0 &&
      this.state.authenticatePassword !== ""
    ) {
      this.setState({ disabled: true });
      const tempEmailAuthenticate = LocalStorageGetValue("userDetails");
      const emails = JSON.parse(tempEmailAuthenticate);
      const token = LocalStorageGetValue("token");
      const loginDetails = {
        email: emails.email,
        password: this.state.authenticatePassword,
        token: token,
      };
      await continueAuthenticates(loginDetails, this);
    }
  };
  okClickedSessionTO = () => {
    removeClickedSessionTO(this);
  };
  okClickedSessionTo = () => {
    removeClickedSessionTo(this);
  };
  backToDashboard = () => {
    let navPath = LocalStorageGetValue("navigationPath");
    if (
      sessionStorage.getItem("prePage") === "/patientCensus" &&
      sessionStorage.getItem("currentPath") === "/econsults_PatientCensus"
    ) {
      if (navPath.length > 2) {
        navPath = JSON.parse(navPath);
        navPath.pop();
        LocalStorageSetValue("navigationPath", JSON.stringify(navPath));
      } else {
        LocalStorageSetValue("navigationPath", []);
      }
      this.props.patientCensusPage();
      this.props.history.push("/patientCensus");
      window.history.replaceState(null, "", "/");
      sessionStorage.setItem("currentPath", "/patientCensus");
      sessionStorage.setItem("prePage", "/dashboard");
    } else if (this.props.location.pathname === "/archiveDetails") {
      this.props.history.replace("/archiveHomepage", {
        fromLocation: "patientArchiveData",
      });
      window.history.replaceState(null, "", "/");
      sessionStorage.setItem("currentPath", "/dashboard");
      sessionStorage.setItem("prePage", "/dashboard");
    } else {
      this.props.eConsultPage();
      LocalStorageSetValue("navigationPath", "");
      this.props.history.push("/dashboard");
      window.history.replaceState(null, "", "/");
      sessionStorage.setItem("currentPath", "/dashboard");
      sessionStorage.setItem("prePage", "/dashboard");
    }
  };
  handleNavPath = (item) => {
    if (item.path === LocalStorageGetValue("currentPath")) {
    } else {
      this.props.history.push(item.path);
      let navPath = JSON.parse(LocalStorageGetValue("navigationPath"));
      if (navPath.length == 2) {
        this.props.eConsultPage();
        this.props.patientCensusPage();
        LocalStorageSetValue("navigationPath", "");
      } else if (item.path === "/patientCensus") {
        this.props.patientCensusPage();
        navPath.pop();
        LocalStorageSetValue("navigationPath", JSON.stringify(navPath));
        this.setState({ navPathLength: navPath.length });
      } else if (item.path === "/dashboard") {
        this.props.eConsultPage();
        LocalStorageSetValue("navigationPath", "");
      }
      sessionStorage.setItem("currentPath", item.path);
    }
  };
  render() {
    window.onblur = () => this.checkOnfocusblur();
    window.onfocus = () => this.checkOnfocusfocus();
    const { classes, children, t } = this.props;
    let { showPassword } = this.state;
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const menuId = "primary-search-account-menu";
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleMenuClose}>{t("profile.label")}</MenuItem>
        <MenuItem onClick={this.handleMenuClose}>
          {t("myaccount.label")}
        </MenuItem>
      </Menu>
    );
    const { userInfo } = this.state;
    let providerName = userInfo?.name;
    let navPath = LocalStorageGetValue("navigationPath");
    return (
      <>
        <PageVisibility onChange={this.listentoWindow}>
          <div className="MainLayoutroot" ref="divFocus">
            <CssBaseline />
            {this.state.audio_callMessageType === "audio_call" &&
              this.state.openSnackbar && (
                <SnackbarComponent
                  callProgressState={this.callProgressState}
                  handleGroupCallFlag={this.handleGroupCallFlag}
                  callProgress={this.state.callProgress}
                  open={true}
                  notification={this.state.notification}
                  message={"Incoming Audio Call"}
                  type={this.state.audio_callMessageType}
                />
              )}
            {this.state.video_callmessageType === "video_call" &&
              this.state.openSnackbar && (
                <SnackbarComponent
                  callProgressState={this.callProgressState}
                  callProgress={this.state.callProgress}
                  open={true}
                  handleGroupCallFlag={this.handleGroupCallFlag}
                  notification={this.state.notification}
                  message={"Incoming Video Call"}
                  type={this.state.video_callmessageType}
                />
              )}
            {this.state.sos_callMessageType === "SOS" &&
              this.state.openSnackbar && (
                <SnackbarComponent
                  callProgressState={this.callProgressState}
                  callProgress={this.state.callProgress}
                  open={true}
                  notification={this.state.notification}
                  message={"Incoming SOS Call"}
                  type={this.state.sos_callMessageType}
                />
              )}
            {this.state.CallerRejectMessageType === "CallerReject" &&
              this.state.openSnackbar && (
                <SnackbarComponent
                  callProgressState={this.callProgressState}
                  callProgress={this.state.callProgress}
                  open={true}
                  notification={this.state.notification}
                  message={this.state.notification.message}
                  type={this.state.CallerRejectMessageType}
                />
              )}
            {this.state.callDisconnected === "callDisconnected" &&
              this.state.openSnackbar && (
                <SnackbarComponent
                  callProgressState={this.callProgressState}
                  callProgress={this.state.callProgress}
                  open={true}
                  notification={this.state.notification}
                  message={this.state.notification.message}
                  type={this.state.callDisconnected}
                />
              )}
            {(this.state.noAnwsering === "No Answering" ||
              this.state.noAnwsering === "CallerNotAnswer") &&
              this.state.openSnackbar && (
                <SnackbarComponent
                  callProgressState={this.callProgressState}
                  callProgress={this.state.callProgress}
                  open={true}
                  notification={this.state.notification}
                  message={this.state.notification.message}
                  type={this.state.noAnwsering}
                />
              )}
            {this.state.sos_dismiss === "sos_dismiss" &&
              this.state.openSnackbar && (
                <SnackbarComponent
                  callProgressState={this.callProgressState}
                  callProgress={this.state.callProgress}
                  open={true}
                  notification={this.state.notification}
                  message={this.state.notification.message}
                  type={this.state.sos_dismiss}
                />
              )}
            <AppBar
              position="fixed"
              elevation={0}
              className={classNames(classes.appBar, {
                [classes.appBarShift]: this.state.open,
              })}
              style={{
                ...(this.props.unsupportedBrowser && {
                  top:
                    document.getElementById("unsupportedbrowser")?.firstChild
                      ?.offsetHeight || 0,
                }),
              }}
            >
              <Toolbar
                style={{
                  minHeight: 0,
                  justifyContent: "space-between",
                  padding: 0,
                }}
              >
                <div>
                  <Toolbar style={{ padding: 0, minHeight: 0 }}>
                    <div className={classes.search} style={{ marginRight: 5 }}>
                      {
                        // (sessionStorage.getItem('currentPath') === "/econsults" || sessionStorage.getItem('currentPath') === "/settings" || sessionStorage.getItem('currentPath') === "/notificationDetails" || sessionStorage.getItem('currentPath') === "/addpatient" || sessionStorage.getItem('currentPath') === '/feedback' ||
                        // sessionStorage.getItem('currentPath') === "/support")
                        sessionStorage.getItem("currentPath") !==
                          "/dashboard" && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              cursor: "pointer",
                            }}
                            onClick={() => this.backToDashboard()}
                          >
                            <img alt="backArrow" src={backArrow} />
                            <Typography className="backto-consult padding-left">{`Back to ${
                              sessionStorage.getItem("prePage") ===
                                "/patientCensus" &&
                              sessionStorage.getItem("currentPath") ===
                                "/econsults_PatientCensus"
                                ? "patient census"
                                : this.props.location.pathname ===
                                  "/archiveDetails"
                                ? "archive patient"
                                : "my eConsult"
                            }`}</Typography>
                          </div>
                        )
                      }
                    </div>
                  </Toolbar>
                </div>
                <Header
                  history={this.props.history}
                  anchorEl={anchorEl}
                  classes={classes}
                  userInfo={userInfo}
                  providerName={providerName}
                  logout={() => this.logout()}
                />
              </Toolbar>
            </AppBar>
            {renderMenu}

            {this.state.lockedUserModel && (
              <Dialog
                open={this.state.lockedUserModel}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                BackdropProps={{
                  classes: {
                    root: classes.backDrop,
                  },
                }}
                aria-labelledby="locked  out"
              >
                <DialogTitle id="form-dialog-lockedout">
                  Locked User
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Clinician is locked by the system.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={() => this.okClickedSessionTO()}
                    color="default"
                    arial-label="Ok logoutout"
                    id="okButton"
                    className="primary-button"
                  >
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            )}

            {!this.state.providerDenied &&
              !this.state.activeOnAnotherDevice &&
              this.state.sessionTimeOutModel && (
                <Dialog
                  open={this.state.sessionTimeOutModel}
                  disableBackdropClick={true}
                  disableEscapeKeyDown={true}
                  BackdropProps={{
                    classes: {
                      root: classes.backDrop,
                    },
                  }}
                  aria-labelledby="Session timeout"
                >
                  <DialogTitle id="form-dialog-session">
                    Session Timeout
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {this.state.sessionTimeOutMessage}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      onClick={() => this.okClickedSessionTO()}
                      color="default"
                      arial-label="Ok sessionout"
                      id="okButton"
                      className="primary-button"
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            {this.state.activeOnAnotherDevice && (
              <Dialog
                open={this.state.activeOnAnotherDevice}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                BackdropProps={{
                  classes: {
                    root: classes.backDrop,
                  },
                }}
                aria-labelledby="activeon another device "
              >
                <DialogTitle id="form-dialog-session">
                  {this.state.invalidateTitle}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {this.state.invalidateMessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={() => this.okClickedSessionTO()}
                    color="default"
                    arial-label="Ok sessionout"
                    id="okButton"
                    className="primary-button"
                  >
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            {this.state.kickout && (
              <Dialog
                open={this.state.kickout}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                BackdropProps={{
                  classes: {
                    root: classes.backDrop,
                  },
                }}
                aria-labelledby="activeon another device "
              >
                <DialogTitle id="form-dialog-session">
                  {this.state.invalidateTitle}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {this.state.invalidateMessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={() => this.okClickedSessionTo()}
                    color="default"
                    arial-label="Ok sessionout"
                    id="okButton"
                    className="primary-button"
                  >
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            )}

            {this.state.providerDenied && (
              <Dialog
                open={this.state.providerDenied}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                BackdropProps={{
                  classes: {
                    root: classes.backDrop,
                  },
                }}
                aria-labelledby="Provider denied by admin"
              >
                <DialogTitle id="form-dialog-session">
                  {this.state.deniedusertitle}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {this.state.deniedusermessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={() => this.okClickedSessionTO()}
                    color="default"
                    arial-label="Ok denied"
                    id="okDButton"
                    className="primary-button"
                  >
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            )}

            {this.state.authenticateModel && (
              <Dialog
                open={this.state.authenticateModel}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                BackdropProps={{
                  classes: {
                    root: classes.backDrop,
                  },
                }}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  Authentication Required
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Please enter your password to continue.
                  </DialogContentText>
                  <TextField
                    fullWidth
                    autoFocus
                    size="small"
                    margin="dense"
                    name="password"
                    placeholder="Password"
                    autoComplete="off"
                    onChange={(e) => this.handleTextFieldChange(e)}
                    onKeyPress={this.handleKeyPress}
                    type={showPassword ? "text" : "password"}
                    defaultValue={this.state.authenticatePassword}
                    variant="outlined"
                    className={"content-placeholder-text"}
                    arial-label="passwordauthenticate"
                    id="passwordauthenticate"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibilitys"
                            id="togglepasswordvisibilitys"
                            onClick={() => this.handleClickShowPassword()}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <div className="footer-div">
                    <Button
                      className="signout-button content-emphasized-sec-text"
                      onClick={() => this.logout()}
                      arial-label="signout "
                      id="signout"
                    >
                      Sign out
                    </Button>
                    <Button
                      disable
                      onClick={() => this.continueAuthenticate()}
                      arial-label="continue authenticate"
                      id="continueButton"
                      className="continue-button captions-text-default"
                    >
                      Continue
                    </Button>
                  </div>
                </DialogActions>
              </Dialog>
            )}

            <Drawer
              airal-label="drawer"
              id="drawer"
              variant="permanent"
              className={classNames(classes.drawer, {
                [classes.drawerOpen]: this.state.open,
                [classes.drawerClose]: !this.state.open,
              })}
              classes={{
                paper: classNames({
                  [classes.drawerOpen]: this.state.open,
                  [classes.drawerClose]: !this.state.open,
                }),
              }}
              PaperProps={{
                style: {
                  ...(this.props.unsupportedBrowser && {
                    top:
                      document.getElementById("unsupportedbrowser")?.firstChild
                        ?.offsetHeight || 0,
                  }),
                },
              }}
              open={this.state.open}
            >
              <div className={classes.drawermainHeader}>
                <List
                  airal-label="profile-header"
                  id="profileHeader"
                  style={{ padding: "10px" }}
                >
                  <ListItem>
                    <ListItemIcon style={{ minWidth: "45px" }}>
                      <img alt="logo" src={logoIcon} />
                    </ListItemIcon>
                    {this.state.open && (
                      <ListItemText
                        primary={
                          <Typography
                            style={{
                              color: "white",
                              fontFamily: "Raleway",
                              fontStyle: "normal",
                              fontWeight: "normal",
                              fontSize: "25px",
                              lineHeight: "20px",
                            }}
                          >
                            {t("omnicureHeader.label")}
                          </Typography>
                        }
                      />
                    )}
                  </ListItem>
                </List>
              </div>
              <div className={classes.drawerHeader}>
                <div className={classes.divIconButton}>
                  <IconButton
                    className={classes.arrowIconButton}
                    aria-label="Menu"
                    onClick={this.handleDrawerOpen}
                    id={this.state.open ? "sidebarOpen" : "sidebarClosed"}
                  >
                    {this.state.open ? (
                      <KeyboardArrowLeftOutlinedIcon
                        className={classes.arrowIcon}
                      />
                    ) : (
                      <KeyboardArrowRightRoundedIcon
                        className={classes.arrowIcon}
                      />
                    )}
                  </IconButton>
                </div>
              </div>
              <Sidebar
                isDrawer={this.state.open}
                history={this.props.history}
                currentUrl={sessionStorage.getItem("currentPath")}
                logout={() => this.logout()}
              />
            </Drawer>
            {this.state.logoutLoader && (
              <Dialog
                open={this.state.logoutLoader}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                BackdropProps={{
                  classes: {
                    root: classes.backDrop,
                  },
                }}
                aria-labelledby="logout loader"
              >
                <LoaderDialog loader={true} />
              </Dialog>
            )}
            {this.state.logoutAlertFlag && (
              <LogoutAlert
                open={this.state.logoutAlertFlag}
                logout={() => this.logout()}
                self={this}
              />
            )}
            <ModelsControl />
            <CallFeedBack />
            <PermissionDenyModel />
            <main
              className={classes.content}
              style={
                this.state.portraitMode
                  ? this.props.location.pathname !== "/econsults" ||
                    this.props.location.pathname !== "/econsults_PatientCensus"
                    ? {
                        flexShrink: 0,
                        width: "calc(100% - 80px)",
                        height:
                          navigator.maxTouchPoints > 2 ||
                          navigator.msMaxTouchPoints > 2
                            ? "-webkit-fill-available"
                            : "100vh",
                      }
                    : {
                        height:
                          navigator.maxTouchPoints > 2 ||
                          navigator.msMaxTouchPoints > 2
                            ? "-webkit-fill-available"
                            : "100vh",
                        display: "flex",
                        flexDirection: "column",
                      }
                  : window.matchMedia("(hover: none) and (pointer: coarse)")
                      .matches &&
                    (navigator.maxTouchPoints > 2 ||
                      navigator.msMaxTouchPoints > 2)
                  ? {
                      height: "-webkit-fill-available",
                      display: "flex",
                      flexDirection: "column",
                    }
                  : {}
              }
            >
              <div
                className={classes.toolbar}
                style={{
                  ...(this.props.unsupportedBrowser && {
                    height:
                      64 +
                        document.getElementById("unsupportedbrowser")
                          ?.firstChild?.offsetHeight || 0,
                  }),
                }}
              />
              {navPath &&
                this.props.location.pathname !== "/notificationDetails" && (
                  <div className="navigationPath">
                    <div>
                      {navPath &&
                        JSON.parse(navPath).length > 0 &&
                        JSON.parse(navPath).map((item, index) => {
                          return (
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "auto 20px",
                                gridGap: "10px",
                              }}
                            >
                              <p
                                onClick={() => this.handleNavPath(item)}
                                style={{
                                  color:
                                    LocalStorageGetValue("currentPath") ===
                                      "/patientCensus" &&
                                    item.page === "Patient Census"
                                      ? "#5E6C84"
                                      : LocalStorageGetValue("currentPath") ===
                                          "/econsults_PatientCensus" &&
                                        item.page ===
                                          JSON.parse(
                                            LocalStorageGetValue(
                                              "currentPatient"
                                            )
                                          )
                                      ? "#5E6C84"
                                      : "#B3BAC5",
                                  cursor:
                                    LocalStorageGetValue("currentPath") ==
                                    item.path
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              >
                                {item.page}
                              </p>
                              <img
                                style={{
                                  display:
                                    JSON.parse(navPath).length - 1 == index
                                      ? "none"
                                      : "block",
                                  paddingTop: "6px",
                                }}
                                src={NavArrow}
                                alt=""
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              {children}
            </main>
          </div>
        </PageVisibility>
      </>
    );
  }
}
MainLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    popUpWindow: state,
    groupCallStatus: state.CallReducerShow.groupCallShow,
    agoraCallsData: state.CallReducerShow.agoraCallsData,
    teamgroupMembers: state.CallReducerShow.teamGroupMembers,
    unsupportedBrowser: state.unsupportedBrowserReducer.unsupportedBrowser,
    zoomWindowOpen: state?.ZoomReducer?.zoomWindowOpen,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getspecialityData() {
      dispatch(specialityData());
    },
    resetCall: () => dispatch(resetCall()),
    leftExpand: () => {
      dispatch(leftPanelExpanded());
    },
    leftCollapse: () => {
      dispatch(leftPanelCollapsed());
    },
    isIncoming: (data) => {
      dispatch(incomingCallAction(data));
    },
    eConsultPage: () => dispatch(eConsultPage(true)),
    patientCensusPage: () => dispatch(patientCensusPage(true)),
    toggleUnsupportedBrowser() {
      dispatch(toggleUnsupportedBrowser());
    },
  };
};
export default withTranslation()(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MainLayout))
);

import axios from "axios";
import { LocalStorageGetValue, browserDetails } from "../Auth";

export const createZoomMeeting = async (data) => {
  const {
    data: { status, errorMessage, zoomMeeting, statusMessage, errorId },
  } = await axios.post("api/commonEndpoints/v1/createZoomMeeting", {
    ...data,
    deviceName: "Website",
  });
  if (status) {
    return zoomMeeting;
  }
  throw errorId || errorMessage || statusMessage;
};

export const getZoomMeeting = async (data) => {
  const {
    data: { status, zoomMeeting, statusMessage, errorMessage },
  } = await axios.post("api/commonEndpoints/v1/getZoomMeeting", data);
  if (status) {
    return zoomMeeting;
  }
  throw errorMessage || statusMessage;
};

export const getZoomToken = async () => {
  try {
    const {
      data: { status, statusMessage },
    } = await axios.get("api/commonEndpoints/v1/zoomTokenCreation");
    if (status) {
      return statusMessage;
    }
    throw new Error();
  } catch (error) {
    console.log(error);
  }
};

export const ignoreZoomMeeting = async (data) => {
  try {
    const { data: responseData } = await axios.post(
      "api/commonEndpoints/v1/updateZoomMeeting",
      data
    );
    return responseData;
  } catch (err) {
    throw err;
    console.log(err);
  }
};

export const getOnlineProvidersByHospitalId = async (hospitalId) => {
  try {
    const {
      data: { providerList = [] },
    } = await axios.post("api/providerEndpoints/v1/getOnlineProviders", {
      hospitalId,
    });
    return providerList;
  } catch (error) {}
};

export const getOfflineProvidersByHospitalId = async (hospitalId) => {
  try {
    const {
      data: { providerList = [] },
    } = await axios.post("api/providerEndpoints/v1/getOfflineProviders", {
      hospitalId,
    });
    return providerList;
  } catch (error) {}
};

export const validateProvidersZoom = async (userIds) => {
  const {
    data: { status, errorMessage },
  } = await axios.post("api/providerEndpoints/v1/validateProvidersCount", {
    userIds,
  });
  if (status) {
    return true;
  }
  throw errorMessage;
};

import produce from "immer";

const initialState = {
  permissionDenyModel: false,
};

export default produce(function (draft, action) {
  if (typeof draft === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case "permissionDenyState": {
      draft.permissionDenyModel = action.payload;
      break;
    }
    default:
      break;
  }
});

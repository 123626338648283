import React from "react";
import "./userprofile.scss";
import {
  Popper,
  Divider,
  Avatar,
  ClickAwayListener,
  Typography,
  CircularProgress,
  Box,
} from "@material-ui/core";
import editProfile from "../../Assets/images/editProfile.png";
import mailIcon from "../../Assets/images/mail.png";
import phoneIcon from "../../Assets/images/phone.png";
import hospitalIcon from "../../Assets/images/hospital.png";
import logout from "../../Assets/images/logout-black.png";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { saveProfilePhoto } from "../../Services/UserProfile/profileService";
import Firebase from "firebase";
import { getTeamDetails } from "../../Services/ConsultsService";
import {
  listProviders,
  listOnlineProviders,
} from "../../Services/ProviderDirectory";
import {
  LocalStorageGetValue,
  LocalStorageSetValue,
} from "../../Services/Auth";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { teamDetails } from "../../Redux/Actions/teamDetailsAction";
import { providerProfileImg } from "../../Redux/Actions/profilePicDisp";
import { updateProviderDetails } from "../../Redux/Actions/providerDetailsAction";
import { useLocation } from "react-router-dom";
import { flatten, pick } from "ramda";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, className, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} className={className}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function UserProfile(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [imageLoading, setImageloading] = React.useState(false);

  const dispatch = useDispatch();
  const econsults = useSelector((state) => state.econsultsReducer?.econsults);
  const location = useLocation();
  const editProfileOpen = () => {
    props.setanchorProvider(null);
    setOpen(true);
  };

  const editProfileClose = () => {
    setOpen(false);
  };

  const logoutFunction = () => {
    props.logout();
  };

  const uploadClick = () => {
    document.getElementById("photoUpload").click();
  };

  const onClickInput = (event) => {
    const value = event.target;
    value.value = "";
  };

  const uploadProfilePic = (event) => {
    let acceptedFiles = ["image/jpeg", "image/png", "image/jpg"];
    const pImage = event.target.files[0];
    if (acceptedFiles.indexOf(pImage.type) > -1) {
      setImageloading(true);
      const user = JSON.parse(LocalStorageGetValue("user"));
      const key = LocalStorageGetValue("id");
      const storageRef = Firebase.storage()
        .ref(user.uid + ".jpg")
        .put(pImage);
      storageRef.on(
        "state_changed",
        (snapshot) => {},
        (error) => {},
        () => {
          const firStorage = Firebase.storage().ref(user.uid + ".jpg");
          firStorage.getDownloadURL().then(async function (result) {
            saveProfilePhoto(result);
            props.setImage(result);
            setImageloading(false);
            let userDetails = JSON.parse(LocalStorageGetValue("userDetails"));
            userDetails.profilePicUrl = result;
            LocalStorageSetValue("userDetails", JSON.stringify(userDetails));
            dispatch(updateProviderDetails({ profilePicUrl: result }));
            if (
              location.pathname === "/econsults" ||
              location.pathname === "/econsults_PatientCensus"
            ) {
              window.setTimeout(async () => {
                let providerDetails = await Promise.all([
                  listProviders("RD"),
                  listProviders("BD"),
                  listOnlineProviders("RD"),
                  listOnlineProviders("BD"),
                ]);
                providerDetails = flatten(providerDetails);
                providerDetails = providerDetails?.map((indiProvider) =>
                  pick(
                    [
                      "name",
                      "email",
                      "id",
                      "profilePicUrl",
                      "firebaseUid",
                      "fname",
                      "lname",
                    ],
                    indiProvider
                  )
                );
                providerDetails.push(
                  pick(
                    [
                      "name",
                      "email",
                      "id",
                      "profilePicUrl",
                      "firebaseUid",
                      "fname",
                      "lname",
                    ],
                    userDetails
                  )
                );
                dispatch(providerProfileImg(providerDetails));
              }, 1000);
            }
          });
        }
      );
    } else {
      toast.warn("Choose a valid file");
    }
  };

  const removeProfilePic = async () => {
    props.setImage(null);
    let userDetails = JSON.parse(LocalStorageGetValue("userDetails"));
    userDetails.profilePicUrl = null;
    LocalStorageSetValue("userDetails", JSON.stringify(userDetails));
    saveProfilePhoto(null);
    dispatch(updateProviderDetails({ profilePicUrl: null }));
    window.setTimeout(async () => {
      if (
        location.pathname === "/econsults" ||
        location.pathname === "/econsults_PatientCensus"
      ) {
        let providerDetails = await Promise.all([
          listProviders("RD"),
          listProviders("BD"),
          listOnlineProviders("RD"),
          listOnlineProviders("BD"),
        ]);
        providerDetails = flatten(providerDetails);
        providerDetails = providerDetails?.map((indiProvider) =>
          pick(
            [
              "name",
              "email",
              "id",
              "profilePicUrl",
              "firebaseUid",
              "fname",
              "lname",
            ],
            indiProvider
          )
        );
        providerDetails.push(
          pick(
            [
              "name",
              "email",
              "id",
              "profilePicUrl",
              "firebaseUid",
              "fname",
              "lname",
            ],
            userDetails
          )
        );
        dispatch(providerProfileImg(providerDetails));
      }
    }, 1000);
  };

  const closeOnClickaway = () => {
    props.setanchorProvider(null);
  };

  return (
    <div className="user-profile">
      <Popper
        id={"1"}
        className="user-profile-popper"
        open={props.anchorProvider}
        anchorEl={props.anchorProvider}
        placement="bottom"
        onClose={() => closeOnClickaway()}
      >
        <ClickAwayListener onClickAway={() => closeOnClickaway()}>
          <div className="edit-profile">
            <div className="popper-header">
              <Typography id="userprof_typo1" className="profile-header">
                {"USER PROFILE"}
              </Typography>
            </div>
            <Divider className="divider-style" />
            <div>
              <div
                className="popper-div-align cursor-change"
                onClick={editProfileOpen}
                value={20}
              >
                <img
                  className="popper-image-align"
                  alt="editProfile"
                  src={editProfile}
                />
                <Typography id="userprof_typo2" className="headerForModel">
                  {"Edit Profile Photo"}
                </Typography>
              </div>
              <div className="popper-div-align">
                <img
                  className="popper-image-align-details"
                  alt="Hosptial"
                  src={hospitalIcon}
                />
                <Typography id="userprof_typo3" className="headerForModel">
                  {props.userInfo?.role === "RD"
                    ? "Remote Clinician"
                    : props.userInfo?.hospital}
                </Typography>
              </div>
              <div className="popper-div-align">
                <img
                  className="popper-image-align-details"
                  alt="Mail"
                  src={mailIcon}
                />
                <Typography
                  id="userprof_typo4"
                  className="headerForModel"
                  style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                >
                  {props.userInfo?.email}
                </Typography>
              </div>
              <div className="popper-div-align">
                <img
                  className="popper-image-align-mobile"
                  alt="Phone No"
                  src={phoneIcon}
                />
                <Typography id="userprof_typo5" className="headerForModel">
                  {props.userInfo?.phone}
                </Typography>
              </div>
              <Divider className="divider-style" />
              <div className="popper-div-align-end" onClick={logoutFunction}>
                <Typography
                  id="userprof_typo6"
                  className="profile-footer cursor-change"
                >
                  {"Log Out"}
                </Typography>
                <img
                  alt="logoutIcon"
                  className="popper-image-align-logout cursor-change"
                  src={logout}
                />
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
      <BootstrapDialog
        id="prof_pic_edit"
        aria-labelledby="customized-dialog-title"
        open={open}
        className="editProfilePopup"
      >
        <BootstrapDialogTitle
          onClose={editProfileClose}
          className={"editHeaderpopup"}
        >
          Edit Profile Photo
        </BootstrapDialogTitle>
        <div className="divider2"></div>
        <div className="row-popup">
          <div className="column-popup">
            {props.profileImage !== null ? (
              <img
                src={props.profileImage}
                className="pImage"
                alt="props.profileImage"
              />
            ) : (
              <Avatar className="avatar" alt="ProfilePicture">
                {props.fName}
                {props.lName}
              </Avatar>
            )}
          </div>
          <div className="column-popup">
            <Button
              onClick={uploadClick}
              id="prof_pic_upload"
              className="btn upload-button"
            >
              Upload Photo
              <input
                onChange={uploadProfilePic}
                onClick={onClickInput}
                type="file"
                id="photoUpload"
                accept=".png,.jpg,.jpeg"
                hidden
              />{" "}
            </Button>
            <Box m={1}>
              {!imageLoading && <div style={{ height: "16px" }}></div>}
            </Box>
            <Box m={1}>
              {imageLoading && (
                <div className="loading-div-photo">
                  <CircularProgress
                    className="check-circular-icon-color"
                    size={13}
                    aria-label="loader"
                    id="loader"
                  />
                  <span className="captions-default">
                    {t("profile.imageChange")}
                  </span>
                </div>
              )}
            </Box>
            <Button
              id="remove_prof_pic"
              className="remove-button"
              onClick={removeProfilePic}
            >
              Remove
            </Button>
          </div>
        </div>
      </BootstrapDialog>
    </div>
  );
}

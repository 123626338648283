export const collapse = () => {
  let profilePic = document.getElementsByClassName("receiverName");
  if (profilePic && profilePic.length > 0) {
    profilePic[0].childNodes[0].style.height = "52px";
    profilePic[0].childNodes[0].style.width = "52px";
  }
  let rmName0 = document.getElementsByClassName("agora-primary-button")[0];
  let rmName1 = document.getElementsByClassName("agora-primary-button")[1];
  let rmName2 = document.getElementsByClassName("agora-primary-button")[2];
  let rmName4 = document.getElementsByClassName("agora-secondary-button")[0];
  if (rmName0 && rmName1 && rmName2 && rmName4) {
    rmName0.childNodes[1].style.display = "none";
    rmName1.childNodes[1].style.display = "none";
    rmName2.childNodes[1].style.display = "none";
    rmName4.childNodes[1].style.display = "none";
  }
  if (document.getElementsByClassName("agora-primary-button").length > 0) {
    document.getElementsByClassName("agora-primary-button")[1].style.width =
      "64px";
    document.getElementsByClassName("agora-primary-button")[1].style.height =
      "56px";
    document.getElementsByClassName("agora-primary-button")[0].style.width =
      "64px";
    document.getElementsByClassName("agora-primary-button")[0].style.height =
      "56px";
    document.getElementsByClassName("agora-primary-button")[2].style.width =
      "64px";
    document.getElementsByClassName("agora-primary-button")[2].style.height =
      "56px";
    document.getElementsByClassName("agora-secondary-button")[0].style.width =
      "64px";
    document.getElementsByClassName("agora-secondary-button")[0].style.height =
      "56px";
  }
  document.getElementsByClassName("callerWindowContainer")[0].style.width =
    "431px";
  document.getElementsByClassName("callerWindowContainer")[0].style.height =
    "281px";
  document.getElementsByClassName(
    "callerWindowDesign"
  )[0].style.gridTemplateRows = "24px 257px";
  /*document.getElementsByClassName("CallStyleDOM")[0].style.top = "12%";
  document.getElementsByClassName("CallStyleDOM")[0].style.right = "3%";
   document.getElementsByClassName("CallStyleDOM")[0].style.transform =
     "translate(0px, 0px)";*/
  if (document.getElementsByClassName("receiverName").length > 0) {
    document.getElementsByClassName("receiverName")[0].style.top = "32%";
    document.getElementsByClassName("receiverName")[0].style.width = "52px";
    document.getElementsByClassName("receiverName")[0].style.height = "52px";
    if (document.getElementsByClassName("innerCircle").length > 0) {
      document.getElementsByClassName("innerCircle")[0].style.border = 0;
      document.getElementsByClassName("innerCircle")[0].style.fontSize = "18px";
      document.getElementsByClassName("innerCircle")[0].style.width = "47px";
      document.getElementsByClassName("innerCircle")[0].style.height = "47px";
    }
  }
  if (document.getElementsByClassName("timerAudio").length > 0) {
    document.getElementsByClassName("timerAudio")[0].style.top = "15%";
    document.getElementsByClassName("timerAudio")[0].style.left = "49%";
  }
  if (document.getElementsByClassName("timerVideo").length > 0) {
    document.getElementsByClassName("timerVideo")[0].style.top = "10%";
    document.getElementsByClassName("timerVideo")[0].style.left = "25px";
  }
  if (document.getElementsByClassName("remoteVideoScreen").length > 0) {
    document.getElementsByClassName("remoteVideoScreen")[0].style.display =
      "none";
  }
  if (document.getElementsByClassName("receiverProfile").length > 0) {
    document.getElementsByClassName("bodyContent")[0].style.padding = 0;
    document.getElementsByClassName("receiverProfile")[0].style.left = "52%";
    document.getElementsByClassName("receiverProfile")[0].style.top = "52%";
    document.getElementsByClassName("receiverProfile")[0].style.transform =
      "translate(-50%,-50%)";
    document.getElementsByClassName("bodyContentBG")[0].style.borderRadius =
      "0px 0px 10px 10px";
    document.getElementsByClassName("operation")[0].style.bottom = "7px";
    document.getElementsByClassName("agora-primary-button")[0].style.width =
      "64px";
    document.getElementsByClassName("agora-primary-button")[0].style.height =
      "56px";
  }
  if (document.getElementsByClassName("connecting_status").length > 0) {
    document.getElementsByClassName("connecting_status")[0].style.top = "61%";
    document.getElementsByClassName("connecting_status")[0].style.left = "37%";
    document.getElementsByClassName("connecting_status")[0].style.fontSize =
      "15px";
  }

  if (document.getElementsByClassName("firstDot").length > 0) {
    document.getElementsByClassName("firstDot")[0].style.width = "7px";
    document.getElementsByClassName("firstDot")[0].style.height = "7px";
    document.getElementsByClassName("firstDot")[1].style.width = "7px";
    document.getElementsByClassName("firstDot")[1].style.height = "7px";
    document.getElementsByClassName("firstDot")[2].style.width = "7px";
    document.getElementsByClassName("firstDot")[2].style.height = "7px";
    document.getElementsByClassName("firstDot")[3].style.width = "7px";
    document.getElementsByClassName("firstDot")[3].style.height = "7px";
  }
  return null;
};

export const Expandable = () => {
  let profilePic = document.getElementsByClassName("receiverName");
  if (profilePic && profilePic.length > 0) {
    profilePic[0].childNodes[0].style.height = "84px";
    profilePic[0].childNodes[0].style.width = "84px";
  }
  document.getElementsByClassName("callerWindowContainer")[0].style.width =
    "60vw";
  document.getElementsByClassName("callerWindowContainer")[0].style.height =
    "auto";
  document.getElementsByClassName(
    "callerWindowDesign"
  )[0].style.gridTemplateRows = "24px 38vw";
  /*document.getElementsByClassName("CallStyleDOM")[0].style.top = "11%";
  document.getElementsByClassName("CallStyleDOM")[0].style.right = "19%";
  document.getElementsByClassName("CallStyleDOM")[0].style.transform =
     "translate(0px, 0px)";*/
  document.getElementsByClassName("bodyContentBG")[0].style.boderRadius =
    "10px";
  if (document.getElementsByClassName("receiverName").length > 0) {
    document.getElementsByClassName("receiverName")[0].style.top = "50%";
    document.getElementsByClassName("receiverName")[0].style.width = "84px";
    document.getElementsByClassName("receiverName")[0].style.height = "84px";
  }
  if (document.getElementsByClassName("timerVideo").length > 0) {
    document.getElementsByClassName("timerVideo")[0].style.top = "6%";
    document.getElementsByClassName("timerVideo")[0].style.left = "7%";
  }
  if (document.getElementsByClassName("timerAudio").length > 0) {
    document.getElementsByClassName("timerAudio")[0].style.top = "37%";
    document.getElementsByClassName("timerAudio")[0].style.left = "49%";
  }
  if (document.getElementsByClassName("remoteVideoScreen").length > 0) {
    document.getElementsByClassName("remoteVideoScreen")[0].style.display =
      "block";
    document.getElementsByClassName("remoteVideoScreen")[0].style.top = "10px";
    document.getElementsByClassName("remoteVideoScreen")[0].style.right =
      "10px";
    document.getElementsByClassName("remoteVideoScreen")[0].style.borderRadius =
      "30px";
  }
  if (document.getElementsByClassName("innerCircle").length > 0) {
    document.getElementsByClassName("innerCircle")[0].style.border =
      "1px solid black";
    document.getElementsByClassName("innerCircle")[0].style.fontSize = "24px";
    document.getElementsByClassName("innerCircle")[0].style.width = "78px";
    document.getElementsByClassName("innerCircle")[0].style.height = "78px";
  }
  if (document.getElementsByClassName("receiverProfile").length > 0) {
    document.getElementsByClassName("receiverProfile")[0].style.left = "51%";
    document.getElementsByClassName("receiverProfile")[0].style.top = "65%";
    document.getElementsByClassName("receiverProfile")[0].style.transform =
      "translate(-50%,-50%)";
  }
  if (document.getElementsByClassName("connecting_status").length > 0) {
    document.getElementsByClassName("connecting_status")[0].style.top = "68%";
    document.getElementsByClassName("connecting_status")[0].style.left = "41%";
    document.getElementsByClassName("connecting_status")[0].style.fontSize =
      "20px";
  }
  document.getElementsByClassName("bodyContent")[0].style.padding = "15px";
  document.getElementsByClassName("agora-primary-button")[0].style.width =
    "64px";
  document.getElementsByClassName("agora-primary-button")[0].style.height =
    "72px";
  if (document.getElementsByClassName("agora-primary-button").length > 0) {
    let rmName0 = document.getElementsByClassName("agora-primary-button")[0];
    rmName0.childNodes[1].style.display = "block";
    let rmName1 = document.getElementsByClassName("agora-primary-button")[1];
    rmName1.childNodes[1].style.display = "block";
    let rmName2 = document.getElementsByClassName("agora-primary-button")[2];
    rmName2.childNodes[1].style.display = "block";
    let rmName4 = document.getElementsByClassName("agora-secondary-button")[0];
    rmName4.childNodes[1].style.display = "block";
  }
  document.getElementsByClassName("agora-primary-button")[0].style.width =
    "64px";
  document.getElementsByClassName("agora-primary-button")[0].style.height =
    "72px";
  document.getElementsByClassName("agora-primary-button")[1].style.width =
    "64px";
  document.getElementsByClassName("agora-primary-button")[1].style.height =
    "72px";
  document.getElementsByClassName("agora-primary-button")[2].style.width =
    "64px";
  document.getElementsByClassName("agora-primary-button")[2].style.height =
    "72px";
  document.getElementsByClassName("agora-secondary-button")[0].style.width =
    "64px";
  document.getElementsByClassName("agora-secondary-button")[0].style.height =
    "72px";
  if (document.getElementsByClassName("firstDot").length > 0) {
    document.getElementsByClassName("firstDot")[0].style.width = "10px";
    document.getElementsByClassName("firstDot")[0].style.height = "10px";
    document.getElementsByClassName("firstDot")[1].style.width = "10px";
    document.getElementsByClassName("firstDot")[1].style.height = "10px";
    document.getElementsByClassName("firstDot")[2].style.width = "10px";
    document.getElementsByClassName("firstDot")[2].style.height = "10px";
    document.getElementsByClassName("firstDot")[3].style.width = "10px";
    document.getElementsByClassName("firstDot")[3].style.height = "10px";
  }
  return null;
};
